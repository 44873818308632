import React, { useState, useEffect, useRef } from 'react';


import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';

import MUIDataTable from "mui-datatables";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import SetColor from './SetColor.js';
import { MuiThemeProvider, createTheme } from '@material-ui/core';

import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

export const ListTeam = () => {
    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);
    const [user, setUser] = useState({});
    // const [PurchaseDetails, setPurchaseDetails] = useState([]);
    const [state, setstate] = useState(2);

    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);

    const [editmodal, setEditModal] = useState([]);
    const [globalDepartments, setGlobalDepartments] = useState([]);
    const [agentsGlobal, setAgentsGlobal] = useState([]);

    const [teamName, setTeamName] = useState("");
    const [teamDepartment, setTeamDepartment] = useState("");
    const [teamAgents, setTeamAgents] = useState([]);
    const [teamDesc, setTeamDesc] = useState("");
    const [status, setstatus] = useState("");

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    const [organization_id, setOrganization_id] = useState("");
    const [organization_list, setorganization_list] = useState([]);
    const [buttonStat, setButtonStat] = useState(1)
    const [statButtonStat, setStatButtonStat] = useState(1)
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    var page_name = "list-team";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };
    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }
    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])
    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Team',
    };



    const columns = [
        {
            name: "team_name",
            label: "Team Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "department",
            label: "Department",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "team_description",
            label: "Description",
            options: {
                filter: true,
                sort: true
            }
        },

        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];


    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);

                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                window.location.href = "/login"
            });
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)

        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)

                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);

                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);

                    });
            }
            else {
                setRedirect_to_login(1);

            }
        }
        organization_id != "" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer) => {
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);


    useEffect(() => {
        async function getuserdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.length > 0) {
                let agent_roles = body.filter(obj => obj.user_role.includes("Agent"))
                setAgentsGlobal(agent_roles)
            }
            else {
                setAgentsGlobal([])
            }

        }
        if (read || write) {
            user.email && visibility && getuserdetails();
        }

        async function getdeparmentdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/department-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            setGlobalDepartments(body);

        }
        if (read || write) {
            user.email && visibility && getdeparmentdetails();
        }

        async function getteamdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/team-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            setEditModal(body);

            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }

            // setloading(true);
        }
        if (read || write) {
            user.email && visibility && getteamdetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function geteditdetails() {

            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {

                    var temp_obj = {};
                    temp_obj.team_name = obj.team_name;
                    temp_obj.dept_id = obj.dept_id;
                    // console.log(globalDepartments.find(dept=>dept.department_id==obj.dept_id).department_title)
                    temp_obj.department = obj.department_title
                    temp_obj.team_description = obj.team_description;
                    temp_obj.agents = JSON.parse(obj.agents);
                    temp_obj.status = obj.status;
                    temp_obj.team_id = obj.team_id;
                    var id = obj.team_id;
                    // console.log(id)
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write ? false : true}>
                                <i className="fas fa-bars px-0"></i>
                            </button>
                            {
                                write ?
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" onClick={(e) => { updateTeam(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                        <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Change status</a></li>
                                        {/* <li><a href="#" onClick={(e) => { updateTeam(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Add/Assign Agents</a></li> */}
                                    </ul>
                                    :
                                    null
                            }

                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();

    }, [editmodal])

    const updateTeam = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].team_id) {
                document.getElementById("id").value = id;
                setTeamDepartment(editmodal[i].dept_id);
                setTeamAgents(JSON.parse(editmodal[i].agents));
                setTeamDesc(editmodal[i].team_description);
                setTeamName(editmodal[i].team_name);
            }
        }

    }
    const updateStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].team_id) {
                document.getElementById("st").value = editmodal[i].status;
                document.getElementById("id").value = id;
                setstatus(editmodal[i].status);
            }
        }
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var id = document.getElementById("id").value;
        var status_value = document.getElementById("st").value;
        var values = { "id": id, "status": status_value, "page_name": page_name, "organization_id": organization_id, "service": "support" };

        //console.log(values);

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        setStatButtonStat(0)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-team`, values, headers).then((res) => {
            setStatButtonStat(1)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Status changed successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }


    const submitTeamDetails = (e) => {
        e.preventDefault();
        var object = {};
        object.id = document.getElementById("id").value;
        object.team_name = teamName.trim()
        object.team_description = teamDesc.trim()
        object.department = teamDepartment
        object.team_agents = teamAgents
        object.page_name = page_name
        object.organization_id = organization_id
        object.service = "support"

        if (object.department == "") {
            swal("Warning!", "Select a department", "warning");
            return false;
        }
        // var nexp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (object.team_name == "") {
            swal("Warning!", "Team Name cannot be a empty", "warning");
            return false;
        }
        else if (!object.team_name.match(nexp)) {
            swal("Warning!", "Team Name cannot contain certain symbols ", "warning");
            return false;
        }
        else if (true) {
            var oname = editmodal.filter((item) => { return item.team_id !== object.id })
            for (var i = 0; i < oname.length; ++i) {
                if (oname[i].team_name == object.team_name) {
                    swal("Oops!", "Name already exists, try different one!", "error");
                    return false;
                }
            }
        }

        if (teamAgents.length < 1) {
            swal("Warning!", "No Agents Selected", "warning");
            return false;
        }
        object.team_agents = teamAgents
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        setButtonStat(0)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-team`, object, headers).then((res) => {
            setButtonStat(1)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Department details updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });


    }
    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
              {
                redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
                    <Redirect to={{ pathname: "/login" }} />
                </> : null)
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Teams
                            {/* <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span> */}
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">

                            <div className="card-body pt-6">
                                {
                                    data.length > 0 ?
                                        <>
                                            <MuiThemeProvider theme={createTheme({ palette: { type: userTheme } })}>
                                                <MUIDataTable
                                                    title={"Team Details"}
                                                    data={data}
                                                    columns={columns}
                                                    options={options}
                                                />
                                            </MuiThemeProvider>

                                        </>
                                        :
                                        <>
                                            {
                                                state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    :
                                                    <>
                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5">Teams</div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                        <div className="fs-6 text-gray-600"> </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    Current Status is : {status == "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                    <h3>Are you sure about changing status?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />

                                {(status == "Active") ?
                                    <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} data-kt-indicator={statButtonStat ? "off" : "on"} disabled={write && statButtonStat ? false : true}>
                                        <span class="indicator-label">Deactivate</span>
                                        <span class="indicator-progress">Please wait...
                                            <span class="spinner-border spinner-border-sm align-middle ms-2">
                                            </span>
                                        </span>
                                    </button>
                                    :
                                    <button type="button" className="btn btn-success" onClick={(e) => { submitStatus(e); }} data-kt-indicator={statButtonStat ? "off" : "on"} disabled={write && statButtonStat ? false : true}>
                                        <span class="indicator-label">Activate</span>
                                        <span class="indicator-progress">Please wait...
                                            <span class="spinner-border spinner-border-sm align-middle ms-2">
                                            </span>
                                        </span>
                                    </button>
                                }
                            </form>
                        </div>

                    </div>

                </div>

            </div>
            <div class="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-650px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>Update Team</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                    <div class="row mb-5">
                                        <div class="col-md-12 fv-row">
                                            <label class="required fs-5 fw-bold mb-2">Department</label>
                                            <Select
                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                className="form-control form-control-solid"
                                                options={globalDepartments.map((e) => { return { label: e.department_title, value: e.department_id } })}
                                                onChange={(e) => { setTeamDepartment(e.value) }}
                                                value={{ label: globalDepartments.find(gd => gd.department_id == teamDepartment) == undefined ? "" : globalDepartments.find(gd => gd.department_id == teamDepartment).department_title, value: teamDepartment }}
                                            />
                                        </div>
                                    </div>
                                    <div class="row mb-5">
                                        <div class="col-md-12 fv-row">
                                            <label class="required fs-5 fw-bold mb-2">Team Name</label>
                                            <input id="team_name" type="text" className="form-control form-control-solid " placeholder="" name="team-name" value={teamName} onChange={(e) => { setTeamName(e.target.value) }} readOnly={write ? false : true} />
                                        </div>
                                    </div>
                                    <div class="row mb-5">
                                        <div class="col-md-12 fv-row">
                                            <label class="required fs-5 fw-bold mb-2">Agents</label>
                                            <Select
                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}

                                                isMulti
                                                className="form-control form-control-solid"
                                                options={agentsGlobal.map((e) => { return { label: e.user_id, value: e.user_unique_id } })}
                                                onChange={(val) => { setTeamAgents(val.map(a => a.value)) }}
                                                value={teamAgents.map(a_id => { return { label: agentsGlobal.find(ag => ag.user_unique_id == a_id).user_id, value: a_id } })}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="d-flex flex-column mb-10 fv-row">
                                            <label className="fs-6 fw-bold mb-2">Description</label>
                                            <textarea id="team_description" className="form-control form-control-solid" rows="3" name="discription" placeholder="" autocomplete="off" value={teamDesc} onChange={(e) => { setTeamDesc(e.target.value) }} readOnly={write ? false : true}></textarea>
                                        </div>
                                    </div>



                                </div>
                            </div>
                            <div class="modal-footer flex-center">
                                <button onClick={(e) => { submitTeamDetails(e) }} type="submit" id="kt_modal_new_address_submit" class="btn btn-primary rounded" data-kt-indicator={buttonStat ? "off" : "on"} disabled={write && buttonStat ? false : true}>
                                    <span class="indicator-label">Submit</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>
            

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />


        </>);

};

export default ListTeam;
