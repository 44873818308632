import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import { Formik } from "formik";
import * as yup from "yup";
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import uuid from 'react-uuid';
import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

function UpdateUser(props) {
    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);
    const [user, setUser] = useState({});
    // const [PurchaseDetails, setPurchaseDetails] = useState([]);
    const [state, setstate] = useState(2);

    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);

    const [Access, setAccess] = useState({ access: [] });
    const [newAccess, setNewAccess] = useState({ access: [] });
    const [newActions, setnewActions] = useState([]);
    const [organization_id, setOrganization_id] = useState("");
    const [organization_list, setorganization_list] = useState([]);

    const [roleId, setRoleId] = useState("");
    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    const [userRoles, setuserRoles] = useState([]);
    const [roleDropdown, setRoleDropdown] = useState([]);
    const [customRole, setCustomRole] = useState(0);
    const [customRoleName, setCustomRoleName] = useState("");

    const [userDetails, setUserDetails] = useState([]);

    const [profiles, setProfiles] = useState([]);
    const [profile, setProfile] = useState({ permissions: [] });
    const [profileDropdown, setProfileDropdown] = useState([]);
    const [customProfile, setCustomProfile] = useState(0);
    const [customProfileName, setCustomProfileName] = useState("");

    const [newProfile, setNewProfile] = useState({ permissions: [] });
    const [profile_name, setProfile_name] = useState("");
    const [profileId, setProfileId] = useState("");
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    // const [buttonStat, setButtonStat] = useState(1)

    var page_name = "update-agents";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };


    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }
    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);

                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                window.location.href = "/login"
            });
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)

        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    // console.log(res.data)
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;

                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }

                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)

                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)

                        }
                    }

                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);

                    });
            }
            else {
                setRedirect_to_login(1);

            }
        }

        organization_id != "" && validateJWT();

    }, [organization_id])
    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer) => {
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);


    async function get_roles() {
        let response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        let body = await response.json();
        if (body.length > 0) {
            setuserRoles(body)
        }
        else {
            throw "roles failed"
        }
    }

    async function get_profiles() {
        let response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-profiles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        let body = await response.json();

        if (body.length > 0) {
            setProfiles(body)
        }
        else {
            throw "profiles failed"
        }
    }

    async function getuserdetails() {
        var object = {};
        object.email = user.email;
        let response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/user-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "unique_id": props.match.params.id, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        let body = await response.json();

        if (body.length > 0) {
            setUserDetails(body);
        }
        else {
            throw "user failed"
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = get_roles();
            let p2 = getuserdetails();
            let p3 = get_profiles();

            Promise.all([p1, p2, p3])
                .then(() => {
                    setstate(1);
                })
                .catch((err) => {
                    setstate(0);
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])
    useEffect(() => {
        if (userRoles.length && userDetails.length && profiles.length) {
            setRoleDropdown(userRoles.map((obj) => { return { label: obj.user_role, value: obj.user_role_id } }))
            setRoleId(userDetails[0].support.role);

            setProfileDropdown(profiles.map((obj) => { return { label: obj.user_profile, value: obj.user_profile_id } }))
            setProfileId(userDetails[0].support.profile_id);

            setAccess(JSON.parse(userRoles.find((role) => role.user_role_id == userDetails[0].support.role).user_role_access))
            setNewAccess(JSON.parse(userRoles.find((role) => role.user_role_id == userDetails[0].support.role).user_role_access))

            setProfile(JSON.parse(profiles.find((profile) => profile.user_profile_id == userDetails[0].support.profile_id).user_profile_actions))
            setNewProfile(JSON.parse(profiles.find((profile) => profile.user_profile_id == userDetails[0].support.profile_id).user_profile_actions))
        }

    }, [userRoles, userDetails, profiles])

    // useEffect(() => {
    //     if (Access.length > 0 && userDetails.length > 0 && state1 == 1) {
    //         // checkFunc(JSON.parse(userDetails[0].user_access).access);
    //         setNewAccess(JSON.parse(userDetails[0].support.permission));
    //     }
    // }, [Access, userDetails, state1])

    useEffect(() => {
        if (!customRole && userRoles.length > 0 && roleId != "") {
            let temp_access = JSON.parse(userRoles.find(role => role.user_role_id == roleId).user_role_access)
            setAccess(temp_access);
            setNewAccess(temp_access);
        }
    }, [customRole, userRoles, roleId])

    useEffect(() => {
        if (!customProfile && profileId != "" && profiles.length > 0) {
            let temp_permission = JSON.parse(profiles.find((profile) => profile.user_profile_id == profileId).user_profile_actions)

            setProfile(temp_permission)
            setNewProfile(temp_permission)
        }
    }, [customProfile, profiles, profileId])

    const handleRole = (event) => {
        setRoleId(event.value);
        setAccess((JSON.parse((userRoles.filter((item) => { return item.user_role_id === event.value }))[0].user_role_access)))
        setNewAccess(JSON.parse((userRoles.filter((item) => { return item.user_role_id === event.value }))[0].user_role_access));
    }

    const handleProfile = (event) => {
        setProfileId(event.value);
        setProfile(JSON.parse(profiles.find((profile) => profile.user_profile_id == event.value).user_profile_actions))
        setNewProfile(JSON.parse(profiles.find((profile) => profile.user_profile_id == event.value).user_profile_actions))
    }

    const updateNewAccess = (val, section, sub1, sub2, part) => {
        let temp = JSON.parse(JSON.stringify(newAccess))
        let temp2 = { access: [] }
        temp2.access = temp.access.map((obj) => {
            if (sub1 == undefined) {
                //root heading
                if (obj.key == section) {
                    return { ...obj, visibility: val ? 1 : 0 }
                }
                else {
                    return { ...obj }
                }
            }
            else {
                let temp3 = obj.inside_components.map((obj2) => {
                    if (part == undefined) {
                        //branch head
                        if (obj2.key == sub1) {
                            return { ...obj2, visibility: val ? 1 : 0 }
                        }
                        else {
                            return { ...obj2 }
                        }
                    }
                    else {
                        if (sub2 == undefined) {
                            //no nested branch
                            if (Object.keys(obj2)[0] == sub1) {
                                return { ...obj2, [part]: val ? 1 : 0 }
                            }
                            else {
                                return { ...obj2 }
                            }
                        }
                        else {
                            //nested branch
                            if (obj2.key == sub1) {
                                let temp4 = obj2.inside_components.map((obj3) => {
                                    if (Object.keys(obj3)[0] == sub2) {
                                        return { ...obj3, [part]: val ? 1 : 0 }
                                    }
                                    else {
                                        return { ...obj3 }
                                    }
                                })
                                return { ...obj2, inside_components: temp4 }
                            }
                            else {
                                return { ...obj2 }
                            }

                        }
                    }
                })

                return { ...obj, inside_components: temp3 }
            }
        })
        setNewAccess(temp2)
    }

    const foreignCheck = (dependencies) => {
        const singleDependency = (dependency) => {
            let module_value = 0
            let item_value = 0
            let option_value = 0
            let final = 1
            let dependencies_tree = dependency.split(".")
            // length 1
            if (dependencies_tree.length >= 1) {
                newProfile.permissions.map((module) => {
                    if (module.key == dependencies_tree[0]) {
                        module_value = module.value
                    }
                })
                final = final * module_value
            }

            // length 2
            if (dependencies_tree.length >= 2 && module_value == 1) {
                newProfile.permissions.map((module) => {
                    if (module.key == dependencies_tree[0]) {
                        module.items.map((item) => {
                            if (item.key == dependencies_tree[1]) {
                                item_value = item.value
                            }
                        })
                    }
                })
                final = final * item_value
            }
            // length 3
            if (dependencies_tree.length >= 3 && module_value == 1 && item_value == 1) {
                newProfile.permissions.map((module) => {
                    if (module.key == dependencies_tree[0]) {
                        module.items.map((item) => {
                            if (item.key == dependencies_tree[1]) {
                                item.options.map((option) => {
                                    if (option.key == dependencies_tree[2]) {
                                        option_value = option.value
                                    }
                                })
                            }
                        })
                    }
                })
                final = final * option_value
            }
            return final
        }
        let foreignFlag = dependencies.map((dependency) => { return singleDependency(dependency) }).reduce((a, b) => a * b, 1)
        // console.log(dependencies,foreignFlag)

        return foreignFlag
    }

    const updateNewPermission = (module_name, item_name, option_name, check_value) => {
        // console.log(module_name, item_name, option_name, check_value)

        let temp = JSON.parse(JSON.stringify(newProfile))
        let temp2 = { permissions: [] }
        temp2.permissions = temp.permissions.map((module) => {
            if (module_name == module.key) {
                if (item_name == undefined) {
                    return { ...module, value: foreignCheck(module.foreign) && check_value ? 1 : 0 }
                }
                else {
                    let temp3 = module.items.map((item) => {
                        if (item_name == item.key) {
                            if (option_name == undefined) {
                                return { ...item, value: foreignCheck(item.foreign) && check_value ? 1 : 0 }
                            }
                            else {
                                let temp4 = item.options.map((option) => {
                                    if (option_name == option.key) {
                                        return { ...option, value: foreignCheck(option.foreign) && check_value ? 1 : 0 }
                                    }
                                    else {
                                        return { ...option }
                                    }
                                })
                                return { ...item, options: temp4 }
                            }
                        }
                        else {
                            return { ...item }
                        }
                    })
                    return { ...module, items: temp3 }
                }
            }
            else {
                return { ...module }
            }
        })
        setNewProfile(temp2)
    }

    const register = () => {

        var object = {};
        object.page_name = page_name;
        object.organization_id = organization_id;
        object.unique_id = userDetails[0].user_unique_id;
        object.email = userDetails[0].user_id;

        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        let checkcount = 0
        // Role
        object.roleId = customRole ? uuid() : roleId
        if (object.roleId == "") {
            swal("Warning!", "Select a role", "warning");
            return false;
        }
        if ((newAccess == { access: [] }) || (newAccess == {})) {
            swal("Oops!", "Role permissions invalid, try again", "error");
            return false;
        }
        object.role_permission = JSON.stringify(newAccess)
        object.customRoleName = customRoleName.trim()
        if (customRole && object.customRoleName == "") {
            swal("Warning!", "Provide a Custom Role name", "warning");
            return false;
        }
        else if (customRole && !object.customRoleName.match(nexp)) {
            swal("Warning!", "Role name cannot contain certain symbols ", "warning");
            return false;
        }
        else {
            userRoles.map((role) => {
                if (customRole && (role.user_role.trim().toLowerCase() == object.customRoleName.toLowerCase())) {
                    swal("Warning!", "Role name already exists", "warning");
                    checkcount++;
                }
                else if (customRole && (JSON.stringify(JSON.parse(role.user_role_access)) == object.role_permission)) {
                    swal("Warning!", `Same Role settings already exists for ${role.user_role}`, "warning");
                    checkcount++;
                }
            })
        }
        // Profile
        object.profileId = customProfile ? uuid() : profileId
        if (object.profileId == "") {
            swal("Warning!", "Select a profile", "warning");
            return false;
        }
        if ((newProfile == { permissions: [] }) || (newProfile == {})) {
            swal("Oops!", "Profile permissions invalid, try again", "error");
            return false;
        }
        object.profile_permission = JSON.stringify(newProfile);
        object.customProfileName = customProfileName.trim()

        if (customProfile && object.customProfileName == "") {
            swal("Warning!", "Provide a Custom Profile name", "warning");
            return false;
        }
        else if (customProfile && !object.customProfileName.match(nexp)) {
            swal("Warning!", "Profile name cannot contain certain symbols ", "warning");
            return false;
        }
        else {
            profiles.map((profile) => {
                if (customProfile && (profile.user_profile.trim().toLowerCase() == object.customProfileName.toLowerCase())) {
                    swal("Warning!", "Profile name already exists", "warning");
                    checkcount++;
                }
                else if (customProfile && (JSON.stringify(JSON.parse(profile.user_profile_actions)) == object.profile_permission)) {
                    swal("Warning!", `Same Profile settings already exists for ${profile.user_profile}`, "warning");
                    checkcount++;
                }
            })
        }
        if (checkcount) {
            return false;
        }
        // console.log(object)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        setMainSubmitButtonState(true)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-user-role-details`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Access details updated successfully!", "success").then((value) => {
                    window.location = "/list-agents"
                });
            }
            else if (res.data.message.includes("already exists")) {
                swal("Oops!", res.data.message, "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }
    const schema = yup.object({

    });
    const ScrollToTop = () => {
        $('html').scrollTop(0);
    }

    const ScrollToBottom = () => {
        // window.scrollTo(0,document.body.scrollHeight);
        $('html,body').animate({ scrollTop: document.body.scrollHeight }, "smooth");
    }

    function loadScripts() {

        var elem = document.getElementById("create_account");
        if (!elem) {
            var se = document.createElement('script');
            se.src = "/assets/js/custom/modals/create-account.js";
            se.id = "create_account";
            document.body.appendChild(se);
        }
    }



    const processTitle = (title) => {
        return title.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    }


    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
                redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
                    <Redirect to={{ pathname: "/login" }} />
                </> : null)
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Update User Access
                            {/* <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span> */}
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div class="card">
                            <div class="card-body">
                                {state == 2 ?
                                    <>
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    </>
                                    :
                                    state == 1 ?
                                        <>
                                            {<div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>}

                                            <button className="btn btn-secondary rounded m-3 p-5 float-right" onClick={ScrollToBottom} title="Scroll to Bottom">
                                                <i class="las la-angle-double-down" style={{ "font-size": "32px" }}></i>
                                            </button>
                                            <div class="stepper stepper-links d-flex flex-column" id="kt_create_account_stepper">
                                                <div class="stepper-nav py-5 mt-5">
                                                    <div class="stepper-item current" data-kt-stepper-element="nav">
                                                        <h3 class="stepper-title">Role</h3>
                                                    </div>
                                                    <div className="stepper-item" data-kt-stepper-element="nav">
                                                        <h3 className="stepper-title">Profile</h3>
                                                    </div>
                                                </div>
                                                <Formik
                                                    validationSchema={schema}
                                                    onSubmit={register}
                                                    initialValues={{

                                                    }} >
                                                    {({
                                                        // handleSubmit,
                                                        handleChange,
                                                        // handleBlur,
                                                        // values,
                                                        // touched,
                                                        // isValid,
                                                        // errors,
                                                    }) => (
                                                        <form class="mx-auto  w-100 p-1" id="kt_create_account_form"
                                                            // onSubmit={(e) => {
                                                            // e.preventDefault();
                                                            // handleSubmit();
                                                            // }}
                                                            noValidate
                                                        >


                                                            <div class="current" data-kt-stepper-element="content">
                                                                <div class="w-100">

                                                                    <div className="row mb-5">
                                                                        <div className="col-md-6 ">
                                                                            <label className="fs-5 fw-bold mb-2">Role</label>
                                                                            <Select
                                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                class="form-select form-select-solid select2-accessible"
                                                                                data-control="select2"
                                                                                options={roleDropdown}
                                                                                value={roleDropdown.find(role => role.value == roleId)}
                                                                                onChange={(event) => { handleRole(event) }}
                                                                            />
                                                                            <span className="form-text text-muted">Select a Role</span>

                                                                        </div>
                                                                        {customRole ?
                                                                            <div className="col-md-6 fv-row">
                                                                                <label className="fs-5 fw-bold mb-2">Custom Role Name</label>
                                                                                <input type="text" name="customer_name" className="form-control" id="customer_name" placeholder="Role Name"
                                                                                    onChange={(e) => setCustomRoleName(e.target.value)}
                                                                                />
                                                                                <span className="form-text text-muted">Please give a Role name.</span>
                                                                            </div>
                                                                            : null
                                                                        }

                                                                    </div>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-6 ">
                                                                            <div className='d-flex container align-items-end justify-content-between my-2 p-0' >
                                                                                <span>Customize Role</span>
                                                                                <div className="form-check form-switch form-check-custom form-check-solid form-check-success mt-2">
                                                                                    <input className="form-check-input w-40px h-20px" type="checkbox" checked={customRole} onChange={e => setCustomRole(e.target.checked)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    {customRole ?
                                                                        <div className="table-responsive">
                                                                            <table className="table table-row-dashed border-gray-100 align-middle gy-6">
                                                                                <tbody className="fs-6 fw-bold">
                                                                                    <tr>
                                                                                        <td className="min-w-240px fs-3 fw-bolder">Pages</td>
                                                                                        <td className="min-w-240px fw-bolder">Visibility</td>
                                                                                        <td className="w-125px">
                                                                                            <label className="form-check-label " for="kt_settings_notification_email">Read</label>
                                                                                        </td>
                                                                                        <td className="w-125px">
                                                                                            <label className="form-check-label " for="kt_settings_notification_phone">Write</label>
                                                                                        </td>

                                                                                    </tr>
                                                                                    {newAccess["access"].length > 0 ? newAccess["access"].map((obj, index1) => {

                                                                                        return (
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td className="fs-4 fw-bolder">{obj.key.toUpperCase()}</td>

                                                                                                    <td>
                                                                                                        <div className="form-check form-check-solid">
                                                                                                            {/* {console.log(obj.visibility)} */}
                                                                                                            <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, undefined, undefined, undefined)} id={`visibility_${obj.key.replace(/-/g, "_")}`} checked={obj.visibility == 1} />
                                                                                                            <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                                {obj.inside_components.length > 0 ? obj.inside_components.map((obj2, index2) => {
                                                                                                    return (
                                                                                                        obj2.hasOwnProperty("key") && obj2.inside_components.length > 0 ?
                                                                                                            <>
                                                                                                                <tr>
                                                                                                                    <td className="ps-4 fs-4 fw-bold">{obj2.key.toUpperCase()}</td>

                                                                                                                    <td>
                                                                                                                        <div className="form-check form-check-solid">
                                                                                                                            {/* {console.log(obj.visibility)} */}
                                                                                                                            <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, undefined, undefined)} id={`visibility_${obj.key.replace(/-/g, "_")}`} checked={obj2.visibility == 1} />
                                                                                                                            <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                        </div>
                                                                                                                    </td>

                                                                                                                </tr>
                                                                                                                {
                                                                                                                    obj2.inside_components.map((obj3) => {
                                                                                                                        // console.log(Object.keys(obj3)[0],":",obj3[Object.keys(obj3)[0]])
                                                                                                                        return (
                                                                                                                            <tr>
                                                                                                                                <td className="ps-8">{Object.keys(obj3)[0]}</td>
                                                                                                                                <td>
                                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], Object.keys(obj3)[0])} id={`visibility_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[0]] == 1} />
                                                                                                                                        <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], "read")} id={`read_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[1]] == 1} />
                                                                                                                                        <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], "write")} id={`write_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[2]] == 1} />
                                                                                                                                        <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <tr>
                                                                                                                <td className="ps-4">{Object.keys(obj2)[0]}</td>
                                                                                                                <td>
                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, Object.keys(obj2)[0])} id={`visibility_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[0]] == 1} />
                                                                                                                        <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, "read")} id={`read_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[1]] == 1} />
                                                                                                                        <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className="form-check form-check-solid">
                                                                                                                        <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, "write")} id={`write_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[2]] == 1} />
                                                                                                                        <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                    )
                                                                                                }) : null}
                                                                                            </>
                                                                                        );
                                                                                    })
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                            <div data-kt-stepper-element="content">
                                                                <div class="w-100 ">
                                                                    {/* <div className="table-responsive">
                                                                    <table className="table table-row-dashed border-gray-100 align-middle gy-6">
                                                                        <tbody className="fs-6 fw-bold">
                                                                            <tr>
                                                                                <td className="min-w-240px fs-3 fw-bolder"></td>
                                                                                <td className="min-w-440px fw-bolder"></td>
                                                                               
                                                                            </tr>
                                                                            
                                                                        </tbody>
                                                                    </table>

                                                                </div> */}
                                                                    {/* <div className=""> */}


                                                                    <div className="row">
                                                                        <div className="col-md-6 fv-row mb-5">
                                                                            <label className="fs-5 fw-bold mb-2">Profile Reference</label>
                                                                            <Select
                                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                class="form-select form-select-solid select2-accessible"
                                                                                data-control="select2"
                                                                                options={profileDropdown}
                                                                                value={profileDropdown.find(profile => profile.value == profileId)}
                                                                                onChange={(event) => { handleProfile(event) }}
                                                                            />
                                                                            <span className="form-text text-muted">Select a already avaiable profile for reference</span>
                                                                        </div>
                                                                        {customProfile ?
                                                                            <div className="col-md-6 fv-row mb-5">
                                                                                <label className="fs-5 fw-bold mb-2">Custom Profile Name</label>
                                                                                <input type="text" name="customer_name" className="form-control" id="customer_name" placeholder="Profile Name"
                                                                                    onChange={(e) => setCustomProfileName(e.target.value)}
                                                                                />
                                                                                <span className="form-text text-muted">Please give a profile name.</span>
                                                                            </div>
                                                                            : null
                                                                        }
                                                                    </div>

                                                                    <div className="row mb-5">
                                                                        <div className="col-md-6 ">
                                                                            <div className='d-flex container align-items-end justify-content-between my-2 p-0' >
                                                                                <span>Customize Profile</span>
                                                                                <div className="form-check form-switch form-check-custom form-check-solid form-check-success mt-2">
                                                                                    <input className="form-check-input w-40px h-20px" type="checkbox" checked={customProfile} onChange={e => setCustomProfile(e.target.checked)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {customProfile ?
                                                                        <div className="row mt-10">
                                                                            <div className="col-12 fv-row">
                                                                                <label className="fs-5 fw-bold mb-2">Permissions</label>
                                                                                <div className="d-flex flex-wrap justify-content-center my-5">
                                                                                    {newProfile.permissions.length > 0 ?
                                                                                        <>
                                                                                            {newProfile.permissions.map((module) => {
                                                                                                return (
                                                                                                    <div className={`card border border-border-2 rounded shadow m-3`} style={{ minHeight: "200px", minWidth: "250px" }} >
                                                                                                        <div className='card-header align-items-center' style={{ minHeight: "40px", padding: "10px" }}>
                                                                                                            {processTitle(module.key)}
                                                                                                            <div className="form-check form-switch form-check-custom form-check-solid form-check-warning mt-2">
                                                                                                                <input className="form-check-input w-40px h-20px" type="checkbox" checked={foreignCheck(module.foreign) && module.value} onChange={(e) => updateNewPermission(module.key, undefined, undefined, e.target.checked)} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className=' p-5' >
                                                                                                            <div className='d-flex justify-content-between flex-column mb-5'>

                                                                                                                {module.items.map((item) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {item.options.length > 0 ?
                                                                                                                                <>
                                                                                                                                    <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(item.key)}>

                                                                                                                                        <div class="dropdown">
                                                                                                                                            <div class="" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                                                                                                                                                <span>{processTitle(item.key)}</span>
                                                                                                                                                <i class="las la-angle-down px-2"></i>
                                                                                                                                            </div>
                                                                                                                                            <ul class="dropdown-menu p-3" aria-labelledby="dropdownMenuButton1">
                                                                                                                                                {item.options.map((option) => {
                                                                                                                                                    return (
                                                                                                                                                        <>
                                                                                                                                                            <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(option.key)}>
                                                                                                                                                                <span>{processTitle(option.key)}</span>

                                                                                                                                                                <input className="form-check-input" type="checkbox" checked={foreignCheck(option.foreign) && option.value} onChange={(e) => updateNewPermission(module.key, item.key, option.key, e.target.checked)} />
                                                                                                                                                            </div>
                                                                                                                                                        </>
                                                                                                                                                    )
                                                                                                                                                })}
                                                                                                                                            </ul>
                                                                                                                                        </div>

                                                                                                                                        <div className="form-check form-switch form-check-custom form-check-solid form-check-success mt-2">
                                                                                                                                            <input className="form-check-input w-40px h-20px" type="checkbox" checked={foreignCheck(item.foreign) && item.value} onChange={(e) => updateNewPermission(module.key, item.key, undefined, e.target.checked)} />
                                                                                                                                        </div>

                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                                :
                                                                                                                                <>
                                                                                                                                    <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(item.key)}>
                                                                                                                                        <span>{processTitle(item.key)}</span>
                                                                                                                                        <div className="form-check form-switch form-check-custom form-check-solid form-check-success mt-2">
                                                                                                                                            <input className="form-check-input w-40px h-20px" type="checkbox" checked={foreignCheck(item.foreign) && item.value} onChange={(e) => updateNewPermission(module.key, item.key, undefined, e.target.checked)} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                    {/* </div> */}
                                                                </div>
                                                            </div>
                                                            <div class="d-flex flex-stack pt-15">
                                                                <div class="mr-2">
                                                                    <button type="button" class="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
                                                                        <span class="svg-icon svg-icon-4 me-1">
                                                                            Previous
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                                <div>
                                                                    {write == 1 && <button
                                                                        type="submit"
                                                                        id="kt_contact_submit_button"
                                                                        class="btn btn-lg btn-primary me-3"
                                                                        data-kt-stepper-action="submit"
                                                                        data-kt-indicator={mainSubmitButtonState ? "on" : "off"}
                                                                        disabled={mainSubmitButtonState}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            // handleSubmit();
                                                                            register();
                                                                        }}

                                                                    >
                                                                        <span className="indicator-label">Update</span>
                                                                        <span className="indicator-progress">Please wait...
                                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                                    </button>}
                                                                    <button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="next">Next
                                                                        <span class="svg-icon svg-icon-4 ms-1 me-0">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                                                    <rect fill="#FFF" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                                                    <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#FFF" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                                                </g>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </div>
                                            <button className="btn btn-secondary rounded m-3 p-5 float-right" onClick={ScrollToTop} title="Scroll to Top">
                                                <i class="las la-angle-double-up" style={{ "font-size": "32px" }}></i>
                                            </button>
                                        </>
                                        :
                                        <>
                                            {/* <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                            <div className="d-flex align-items-center p-3 mb-2"></div> */}
                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                <div className="d-flex flex-stack flex-grow-1">
                                                    <div className="fw-bold">
                                                        <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                                        <div className="fs-6 text-gray-600">
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />


        </>
    );
}
export default UpdateUser;