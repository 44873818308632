import React, { useState, useEffect, useRef } from 'react';

import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom'
//import process.env from '../config.json';
import axios from 'axios';
import swal from 'sweetalert';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import removeImg from './icons/remove.svg'
import { v4 as uuidv4 } from 'uuid'
import { v1 as uuidv1 } from 'uuid'
import './timer-style.css';
import "@pathofdev/react-tag-input/build/index.css";
import language_list from './language-arr.js';

import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

function AddTicket(props) {
    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);
    const [user, setUser] = useState({});
    // const [PurchaseDetails, setPurchaseDetails] = useState([]);
    const [state, setstate] = useState(2);

    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    const [tid, set_tid] = useState(uuidv4());
    const [convId, set_convId] = useState(uuidv1());

    const [departmentList, setDepartmentList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [agentsGlobal, setAgentsGlobal] = useState([]);
    const [usersGlobal, setUsersGlobal] = useState([]);

    const [buttonStat, setButtonStat] = useState(1)

    const [ticketCustomerName, setTickeCustomerName] = useState("");
    const [ticketPhone, setTickePhone] = useState("");
    const [ticketUserName, setTickeUserName] = useState("");
    const [ticketUser, setTickeUser] = useState("");
    const [ticketSubject, setTicketSubject] = useState("");
    const [ticketDescription, setTicketDescription] = useState("");
    const [ticketProduct, setTicketProduct] = useState("");
    const [ticketStatus, setTicketStatus] = useState("Open");
    const [ticketDepartment, setTicketDepartment] = useState("");
    const [ticketAgent, setTicketAgent] = useState("");
    const [ticketChannel, setTicketChannel] = useState("Email");
    const [ticketClassification, setTicketClassification] = useState("");
    const [tags, setTags] = useState([]);
    const [ticketLanguage, setTicketLanguage] = useState("English");
    const [ticketPriority, setTicketPriority] = useState("");
    const [due_date, setDue_date] = useState("");

    let [fileNames, setFileNames] = useState([]);

    const [organization_list, setorganization_list] = useState([]);

    const [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [organization_id, setOrganization_id] = useState("");
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    setFileNames = (name) => {
        fileNames.push(name)
        fileNames = [...new Set(fileNames)];
        fileNames = fileNames.slice(-5)
    }
    var page_name = "add-ticket";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };
    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }
    
    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])
    

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);
                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                window.location.href = "/login"
            });
        }
    }, [])

    useEffect(() => {        
        setCookie("lastpage", window.location.href, 1, false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)

                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);

                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);

                    });
            }
            else {
                setRedirect_to_login(1);

            }
        }
        organization_id != "" && validateJWT();
    }, [organization_id])
    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer) => {
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);

    useEffect(() => {

        async function getdepartment() {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/department-datatable`, {
                method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
            }
            );
            const body = await response.json();
            if (body.length > 0) {
                setDepartmentList(body.map(obj => { return { label: obj.department_title, value: obj.department_id } }))
            }
            else {
                setDepartmentList([])
            }
        }
        if (read || write) {
            (user.email) && getdepartment();
        }

        async function getproduct() {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/product-datatable`, {
                method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
            }
            );
            const body = await response.json();
            if (body.length > 0) {
                setProductList(body.map(obj => { return { label: obj.product_name, value: obj.pid } }))
            }
            else {
                setProductList([])
            }
        }
        if (read || write) {
            (user.email) && getproduct();
        }

        async function getuserdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if (body.length > 0) {
                let agent_roles = body.filter(obj => obj.user_role.includes("Agent"))
                setAgentsGlobal(agent_roles)
                setUsersGlobal(body)
            }
            else {
                setAgentsGlobal([])
                setUsersGlobal([])
            }

        }
        if (read || write) {
            user.email && visibility && getuserdetails();
        }

    }, [user, visibility, read, write])

    const onsubmit = (e) => {
        e.preventDefault();
        let object = {};
        object.page_name = page_name;

        object.tid = tid
        object.conv_id = convId

        object.customer_name = ticketCustomerName
        object.phone = ticketPhone
        object.account_name = ticketUserName
        object.email = ticketUser

        object.subject = ticketSubject
        object.description = ticketDescription

        object.product = ticketProduct
        object.status = ticketStatus

        object.department = ticketDepartment
        object.agent = ticketAgent

        if (ticketAgent != "") {
            object.ownerAgent = JSON.stringify({ user: [{ user_id: ticketAgent, email_id: agentsGlobal.find(agent => agent.user_unique_id == ticketAgent).user_id, view: 1, communicate: 1, edit: 1 }], dept: [], team: [], role: [] })
        } else {
            object.ownerAgent = JSON.stringify({ user: [], dept: [], team: [], role: [] })
        }
        object.channel = ticketChannel
        object.classification = ticketClassification
        object.tags = JSON.stringify(tags)
        object.language = ticketLanguage
        object.priority = ticketPriority

        object.due_date = due_date
        object.fileNames = fileNames
        object.organization_id = organization_id
        object.service = "support"

        let nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (object.customer_name == "") {
            swal("Warning!", "Provide customer name", "warning");
            return false;
        }
        else if (!object.customer_name.match(nexp)) {
            swal("Warning!", "customer Name cannot contain certain symbols ", "warning");
            return false;
        }

        let exp = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (object.phone.trim() == "" && object.email.trim() == "") {
            swal("Warning!", "Provide atleast email/phone", "warning");
            return false;
        }
        if (object.email.trim() != "" && !object.email.match(exp)) {
            swal("Oops!", `Please enter a valid email id!`, "error");
            return false;
        }

        var pexp = /^([0|\+[0-9]{1,5})?([0-9]{1,15})$/
        if (object.phone.trim() != "" && !pexp.test(object.phone.trim())) {
            swal("Oops!", `Please enter a valid phone number`, "error");
            return false;
        }

        if (object.subject == "") {
            swal("Warning!", "Provide Subject for the Ticket", "warning");
            return false;
        }
        if (object.status == "") {
            swal("Warning!", "No Status specified", "warning");
            return false;
        }
        if (object.department == "") {
            swal("Warning!", "No Department specified", "warning");
            return false;
        }
        if (object.channel == "") {
            swal("Warning!", "No Channel specified", "warning");
            return false;
        }
        if (object.language == "") {
            swal("Warning!", "No Language specified", "warning");
            return false;
        }

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        // console.log(object)
        setButtonStat(0)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-ticket`, object, headers).then((res) => {
            setButtonStat(1)
            // if (res.data.operation === "duplicate_name") {
            //     swal("Oops!", "product with this name already exist!!", "error");
            // }
            // else if (res.data.operation === "duplicate_sku") {
            //     swal("Oops!", "product with this sku already exist!!", "error");
            // }
            // else 
            if (res.data && res.data.operation == "success") {
                //console.log(res.data);
                swal("Great!", "Ticket Created Successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });

    }
    var files = [];
    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        // Remove previews which do not pass the validation
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (

            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>

        );
    };

    const handleChangeStatus2 = ({ meta }, status) => {
        const { name } = meta;
        let updatedFiles = [];
        if (status === 'done' || status === 'removed') {
            // Add meta to files
            if (status === 'done') {
                updatedFiles = [...files, meta];
                setButtonStat(1)
            }
            // Remove meta from files
            else if (status === 'removed') {
                setButtonStat(1)

                fileNames = fileNames.filter(item => item !== String(name))
                // if (fileNames.length < 3) {
                //     for (let i = fileNames.length; i < 3; i++) {
                //         fileNames.push("");
                //         fileNames.sort()
                //         fileNames.reverse();
                //     }
                // }

            }
            else {
                updatedFiles = files.filter(files, (file) => {
                    return file.name !== meta.name
                        || file.size !== meta.size
                        || file.type !== meta.type;
                });

            }
            files = updatedFiles;
        }
        console.log(fileNames)
    };

    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
        files = [];
    };
    const handleValidation2 = ({ meta }) => {
        return files.find(e => e.name === meta.name && e.size === meta.size && e.type === meta.type);
    }


    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        // console.log(meta);
        setFileNames(String(name))
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        //console.log(file);
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('id', tid)
        body.append('conv_id', convId)
        body.append('page_name', page_name);
        body.append('organization_id', organization_id)
        body.append('service', "support")

        // console.log(body);
        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        setButtonStat(0)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-attachment`, headers, body }
    }

    // handle the status of the file upload
    const handleChangeStatus = ({ xhr }) => {
        //console.log('handleChangeStatus');
        if (xhr) {
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    const result = JSON.parse(xhr.response);
                    // console.log(result);
                }
            }
        }
    }

    const checkEmail = (email) => {
        setTickeUser(email.trim())
        let matchAccount = usersGlobal.find(user => user.user_id.trim().toLowerCase() === email.trim().toLowerCase())
        setTickeUserName(matchAccount == undefined ? "" : matchAccount.user_id)
    }
    // console.log(ticketDepartment);


    return (
        <>

            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
                redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
                    <Redirect to={{ pathname: "/login" }} />
                </> : null)
            }
            {user.email ?
                <>

                    <div className="d-flex flex-column flex-column-fluid">
                        <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Ticket Entry
                                    {/* <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span> */}
                                    <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                </h1>
                            </div>
                        </div>

                        <div id="kt_app_content" className="app-content flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container container-xxl">
                                <div className="card">
                                    <div class="card-body p-lg-17">

                                        <div class="row mb-3">
                                            <div class="col-md-12 pe-lg-10">
                                                <form class="form mb-15" id="kt_contact_form">

                                                    <div className=" py-10 px-lg-17">

                                                        <div className="me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                                            <div className="kt-portlet__body">
                                                                <div className="row justify-content-center">
                                                                    {/* Account & Sender Details */}
                                                                    <div className="row mb-5">

                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="required fs-5 fw-bold mb-2">Customer Name</label>
                                                                            <input type="text" name="customer_name" className="form-control" id="customer_name" placeholder="Customer Name"
                                                                                onChange={(e) => { setTickeCustomerName(e.target.value) }}
                                                                            />
                                                                            <span className="form-text text-muted">Please enter Customer name.</span>

                                                                        </div>

                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Phone</label>
                                                                            <input id="phone" type="tel" class="form-control" placeholder="phone No" name="phone"
                                                                                onChange={(e) => { setTickePhone(e.target.value) }}
                                                                            />

                                                                            <span className="form-text text-muted">Add a phone number</span>

                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-5">

                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Account Name</label>
                                                                            <input type="text" name="account_name" className="form-control form-control-solid" id="account_name" placeholder="Account Name"
                                                                                // onChange={(e) => { setproduct_name(e.target.value) }}
                                                                                value={ticketUserName}
                                                                                readOnly
                                                                            />

                                                                        </div>

                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Email</label>
                                                                            <input id="email" type="text" class="form-control" placeholder="email" name="email"
                                                                                onChange={(e) => { checkEmail(e.target.value) }}
                                                                            />
                                                                            <span className="form-text text-muted">Add Customer Email</span>

                                                                        </div>
                                                                    </div>

                                                                    {/* Ticket Details */}
                                                                    <div className="row mb-5">

                                                                        <div className="col-md-12 fv-row">
                                                                            <label className="required fs-5 fw-bold mb-2">Subject</label>
                                                                            <input type="text" name="subject" className="form-control form-control" id="customer_name" placeholder="Ticket Subject"
                                                                                onChange={(e) => { setTicketSubject(e.target.value) }}
                                                                                value={ticketSubject}
                                                                            />
                                                                            <span className="form-text text-muted">Please add a Subject to the Ticket</span>

                                                                        </div>

                                                                        <div className="col-md-16 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2"></label>
                                                                            <textarea type="text" name="description" className="form-control form-control" id="customer_name" placeholder="Description"
                                                                                onChange={(e) => { setTicketDescription(e.target.value) }}
                                                                                value={ticketDescription}
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                    {/* Assign Details */}
                                                                    <div className="row mb-5">

                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Product</label>
                                                                            <Select
                                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                class="form-select form-select-solid select2-accessible"
                                                                                data-control="select2"
                                                                                options={productList}
                                                                                onChange={(e) => { setTicketProduct(e.value) }}
                                                                            />
                                                                            <span className="form-text text-muted">Select a Product related to Ticket</span>

                                                                        </div>

                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Status</label>
                                                                            <Select
                                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                // className="form-control form-control-solid"
                                                                                options={[
                                                                                    { label: "Open", value: "Open" },
                                                                                    { label: "on Hold", value: "on Hold" },
                                                                                    { label: "Closed", value: "Closed" }
                                                                                ]}
                                                                                defaultValue={{ label: "Open", value: "Open" }}
                                                                                onChange={(e) => { setTicketStatus(e.value) }}

                                                                            />
                                                                            <span className="form-text text-muted">Select Status of Ticket</span>

                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-5">

                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="required fs-5 fw-bold mb-2">Department</label>
                                                                            <Select
                                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}

                                                                                // className="form-control form-control-solid"
                                                                                options={departmentList}
                                                                                onChange={(e) => { setTicketDepartment(e.value) }}
                                                                            />
                                                                            <span className="form-text text-muted">Select The Department for Ticket</span>

                                                                        </div>

                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Assign Agent</label>
                                                                            <Select
                                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                // className="form-control form-control-solid"
                                                                                options={[{ label: "Unassigned", value: "" }, ...agentsGlobal.map((e) => { return { label: e.user_id, value: e.user_unique_id } })]}
                                                                                onChange={(e) => { setTicketAgent(e.value) }}
                                                                            />
                                                                            <span className="form-text text-muted">Assign Agent for Ticket</span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="row mb-5">
                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Channel</label>
                                                                            <Select
                                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                // className="form-control form-control-solid"
                                                                                options={[
                                                                                    { label: "Email", value: "Email" },
                                                                                    { label: "Phone", value: "Phone" },
                                                                                    { label: "Web form", value: "Web form" },
                                                                                    { label: "Chat", value: "Chat" },
                                                                                    { label: "Social", value: "Social" },
                                                                                    { label: "Other", value: "Other" }
                                                                                ]}
                                                                                defaultValue={{ label: "Email", value: "Email" }}
                                                                                onChange={(e) => { setTicketChannel(e.value) }}

                                                                            />
                                                                            <span className="form-text text-muted">Select Ticket Priority</span>

                                                                        </div>
                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Classification</label>
                                                                            <Select
                                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                options={[
                                                                                    { label: "-None-", value: "" },
                                                                                    { label: "Question", value: "Question" },
                                                                                    { label: "Problem", value: "Problem" },
                                                                                    { label: "Feature", value: "Feature" },
                                                                                    { label: "Others", value: "Others" }
                                                                                ]}
                                                                                defaultValue={{ label: "-None-", value: "" }}
                                                                                onChange={(e) => { setTicketClassification(e.value) }}
                                                                            />
                                                                            <span className="form-text text-muted">Classify the Ticket type</span>

                                                                        </div>

                                                                    </div>
                                                                    <div className="row mb-5">

                                                                        <div className="col-md-12 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Language</label>
                                                                            <Select
                                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                // className="form-control form-control-solid"
                                                                                options={language_list.language_list}
                                                                                onChange={(e) => { setTicketLanguage(e.value) }}
                                                                                defaultValue={{ "code": "en", value: "English", label: "English" }}
                                                                            />
                                                                            <span className="form-text text-muted">Select support Language</span>

                                                                        </div>

                                                                    </div>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Priority</label>
                                                                            <Select
                                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                // className="form-control form-control-solid"
                                                                                options={[
                                                                                    { label: "-None-", value: "" },
                                                                                    { label: "High", value: "High" },
                                                                                    { label: "Medium", value: "Medium" },
                                                                                    { label: "Low", value: "Low" }
                                                                                ]}
                                                                                defaultValue={{ label: "-None-", value: "" }}
                                                                                onChange={(e) => { setTicketPriority(e.value) }}

                                                                            />
                                                                            <span className="form-text text-muted">Select Ticket Priority</span>

                                                                        </div>
                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Due Date</label>
                                                                            <input type="datetime-local" name="start_date" className="form-control" id="start_date" placeholder="Due Date" value={due_date}
                                                                                onChange={(e) => {
                                                                                    setDue_date(e.target.value);
                                                                                    // console.log(typeof e.target.value);
                                                                                }}
                                                                            />
                                                                            <span className="form-text text-muted">Select Due Date and Time.</span>

                                                                        </div>

                                                                    </div>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-12 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">Attachments</label>
                                                                            <Dropzone
                                                                                getUploadParams={getUploadParams}
                                                                                onChangeStatus={handleChangeStatus, handleChangeStatus2}
                                                                                onSubmit={handleSubmit}
                                                                                validate={handleValidation2}
                                                                                LayoutComponent={CustomLayout}
                                                                                styles={{
                                                                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                                    inputLabelWithFiles: { margin: '20px 3%' }
                                                                                }}
                                                                                maxFiles={5}
                                                                                minSize={0}
                                                                                maxSize={2097152}
                                                                                // canRemove={false}
                                                                                PreviewComponent={Preview}
                                                                                accept="image/*,.pdf"
                                                                            />
                                                                            <span className="form-text text-muted">Upload 5 files upto 2 MB each(Supports only Image,Pdf)</span>
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="modal-footer flex-center">
                                                            <button style={{ marginTop: '10px' }} onClick={(e) => { onsubmit(e) }} type="button" class="btn btn-primary rounded" id="kt_contact_submit_button" data-kt-indicator={buttonStat ? "off" : "on"} disabled={write && buttonStat ? false : true}>
                                                                <span class="indicator-label">Submit</span>
                                                                <span class="indicator-progress">Please wait...
                                                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                            </button>
                                                        </div>


                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </>
                :
                <>
                    <div className="d-flex flex-column flex-column-fluid">
                        <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Ticket Entry
                                    {/* <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span> */}
                                    <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                </h1>
                            </div>
                        </div>

                        <div id="kt_app_content" className="app-content flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container container-xxl">
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <br />

                                    {state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <>

                                            <div className="flex-lg-row-fluid me-lg-15">
                                                <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            {/* <h2 className="fw-bolder">Create Live Alerts</h2> */}
                                                        </div>
                                                    </div>


                                                    <div className="card-body pt-0">
                                                        <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                        <div className="d-flex align-items-center p-3 mb-2">


                                                        </div>


                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">Please Login</h4>
                                                                    <div className="fs-6 text-gray-600">
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </>
                                    }


                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />



        </>
    );
}
export default AddTicket;