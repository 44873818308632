
var language_arr = new Array(

    { "code": "en", "value": "English", "label": "English" },
    { "code": "ab", "value": "Abkhaz", "label": "аҧсуа" },
    { "code": "aa", "value": "Afar", "label": "Afaraf" },
    { "code": "af", "value": "Afrikaans", "label": "Afrikaans" },
    { "code": "ak", "value": "Akan", "label": "Akan" },
    { "code": "sq", "value": "Albanian", "label": "Shqip" },
    { "code": "am", "value": "Amharic", "label": "አማርኛ" },
    { "code": "ar", "value": "Arabic", "label": "العربية" },
    { "code": "an", "value": "Aragonese", "label": "Aragonés" },
    { "code": "hy", "value": "Armenian", "label": "Հայերեն" },
    { "code": "as", "value": "Assamese", "label": "অসমীয়া" },
    { "code": "av", "value": "Avaric", "label": "авар мацӀ, магӀарул мацӀ" },
    { "code": "ae", "value": "Avestan", "label": "avesta" },
    { "code": "ay", "value": "Aymara", "label": "aymar aru" },
    { "code": "az", "value": "Azerbaijani", "label": "azərbaycan dili" },
    { "code": "bm", "value": "Bambara", "label": "bamanankan" },
    { "code": "ba", "value": "Bashkir", "label": "башҡорт теле" },
    { "code": "eu", "value": "Basque", "label": "euskara, euskera" },
    { "code": "be", "value": "Belarusian", "label": "Беларуская" },
    { "code": "bn", "value": "Bengali", "label": "বাংলা" },
    { "code": "bh", "value": "Bihari", "label": "भोजपुरी" },
    { "code": "bi", "value": "Bislama", "label": "Bislama" },
    { "code": "bs", "value": "Bosnian", "label": "bosanski jezik" },
    { "code": "br", "value": "Breton", "label": "brezhoneg" },
    { "code": "bg", "value": "Bulgarian", "label": "български език" },
    { "code": "my", "value": "Burmese", "label": "ဗမာစာ" },
    { "code": "ca", "value": "Catalan; Valencian", "label": "Català" },
    { "code": "ch", "value": "Chamorro", "label": "Chamoru" },
    { "code": "ce", "value": "Chechen", "label": "нохчийн мотт" },
    { "code": "ny", "value": "Chichewa; Chewa; Nyanja", "label": "chiCheŵa, chinyanja" },
    { "code": "zh", "value": "Chinese", "label": "中文 (Zhōngwén), 汉语, 漢語" },
    { "code": "cv", "value": "Chuvash", "label": "чӑваш чӗлхи" },
    { "code": "kw", "value": "Cornish", "label": "Kernewek" },
    { "code": "co", "value": "Corsican", "label": "corsu, lingua corsa" },
    { "code": "cr", "value": "Cree", "label": "ᓀᐦᐃᔭᐍᐏᐣ" },
    { "code": "hr", "value": "Croatian", "label": "hrvatski" },
    { "code": "cs", "value": "Czech", "label": "česky, čeština" },
    { "code": "da", "value": "Danish", "label": "dansk" },
    { "code": "dv", "value": "Divehi; Dhivehi; Maldivian;", "label": "ދިވެހި" },
    { "code": "nl", "value": "Dutch", "label": "Nederlands, Vlaams" },
    { "code": "eo", "value": "Esperanto", "label": "Esperanto" },
    { "code": "et", "value": "Estonian", "label": "eesti, eesti keel" },
    { "code": "ee", "value": "Ewe", "label": "Eʋegbe" },
    { "code": "fo", "value": "Faroese", "label": "føroyskt" },
    { "code": "fj", "value": "Fijian", "label": "vosa Vakaviti" },
    { "code": "fi", "value": "Finnish", "label": "suomi, suomen kieli" },
    { "code": "fr", "value": "French", "label": "français, langue française" },
    { "code": "ff", "value": "Fula; Fulah; Pulaar; Pular", "label": "Fulfulde, Pulaar, Pular" },
    { "code": "gl", "value": "Galician", "label": "Galego" },
    { "code": "ka", "value": "Georgian", "label": "ქართული" },
    { "code": "de", "value": "German", "label": "Deutsch" },
    { "code": "el", "value": "Greek, Modern", "label": "Ελληνικά" },
    { "code": "gn", "value": "Guaraní", "label": "Avañeẽ" },
    { "code": "gu", "value": "Gujarati", "label": "ગુજરાતી" },
    { "code": "ht", "value": "Haitian; Haitian Creole", "label": "Kreyòl ayisyen" },
    { "code": "ha", "value": "Hausa", "label": "Hausa, هَوُسَ" },
    { "code": "he", "value": "Hebrew (modern)", "label": "עברית" },
    { "code": "hz", "value": "Herero", "label": "Otjiherero" },
    { "code": "hi", "value": "Hindi", "label": "हिन्दी, हिंदी" },
    { "code": "ho", "value": "Hiri Motu", "label": "Hiri Motu" },
    { "code": "hu", "value": "Hungarian", "label": "Magyar" },
    { "code": "ia", "value": "Interlingua", "label": "Interlingua" },
    { "code": "id", "value": "Indonesian", "label": "Bahasa Indonesia" },
    { "code": "ie", "value": "Interlingue", "label": "Originally called Occidental; then Interlingue after WWII" },
    { "code": "ga", "value": "Irish", "label": "Gaeilge" },
    { "code": "ig", "value": "Igbo", "label": "Asụsụ Igbo" },
    { "code": "ik", "value": "Inupiaq", "label": "Iñupiaq, Iñupiatun" },
    { "code": "io", "value": "Ido", "label": "Ido" },
    { "code": "is", "value": "Icelandic", "label": "Íslenska" },
    { "code": "it", "value": "Italian", "label": "Italiano" },
    { "code": "iu", "value": "Inuktitut", "label": "ᐃᓄᒃᑎᑐᑦ" },
    { "code": "ja", "value": "Japanese", "label": "日本語 (にほんご／にっぽんご)" },
    { "code": "jv", "value": "Javanese", "label": "basa Jawa" },
    { "code": "kl", "value": "Kalaallisut, Greenlandic", "label": "kalaallisut, kalaallit oqaasii" },
    { "code": "kn", "value": "Kannada", "label": "ಕನ್ನಡ" },
    { "code": "kr", "value": "Kanuri", "label": "Kanuri" },
    { "code": "ks", "value": "Kashmiri", "label": "कश्मीरी, كشميري‎" },
    { "code": "kk", "value": "Kazakh", "label": "Қазақ тілі" },
    { "code": "km", "value": "Khmer", "label": "ភាសាខ្មែរ" },
    { "code": "ki", "value": "Kikuyu, Gikuyu", "label": "Gĩkũyũ" },
    { "code": "rw", "value": "Kinyarwanda", "label": "Ikinyarwanda" },
    { "code": "ky", "value": "Kirghiz, Kyrgyz", "label": "кыргыз тили" },
    { "code": "kv", "value": "Komi", "label": "коми кыв" },
    { "code": "kg", "value": "Kongo", "label": "KiKongo" },
    { "code": "ko", "value": "Korean", "label": "한국어 (韓國語), 조선말 (朝鮮語)" },
    { "code": "ku", "value": "Kurdish", "label": "Kurdî, كوردی‎" },
    { "code": "kj", "value": "Kwanyama, Kuanyama", "label": "Kuanyama" },
    { "code": "la", "value": "Latin", "label": "latine, lingua latina" },
    { "code": "lb", "value": "Luxembourgish, Letzeburgesch", "label": "Lëtzebuergesch" },
    { "code": "lg", "value": "Luganda", "label": "Luganda" },
    { "code": "li", "value": "Limburgish, Limburgan, Limburger", "label": "Limburgs" },
    { "code": "ln", "value": "Lingala", "label": "Lingála" },
    { "code": "lo", "value": "Lao", "label": "ພາສາລາວ" },
    { "code": "lt", "value": "Lithuanian", "label": "lietuvių kalba" },
    { "code": "lu", "value": "Luba-Katanga", "label": "" },
    { "code": "lv", "value": "Latvian", "label": "latviešu valoda" },
    { "code": "gv", "value": "Manx", "label": "Gaelg, Gailck" },
    { "code": "mk", "value": "Macedonian", "label": "македонски јазик" },
    { "code": "mg", "value": "Malagasy", "label": "Malagasy fiteny" },
    { "code": "ms", "value": "Malay", "label": "bahasa Melayu, بهاس ملايو‎" },
    { "code": "ml", "value": "Malayalam", "label": "മലയാളം" },
    { "code": "mt", "value": "Maltese", "label": "Malti" },
    { "code": "mi", "value": "Māori", "label": "te reo Māori" },
    { "code": "mr", "value": "Marathi (Marāṭhī)", "label": "मराठी" },
    { "code": "mh", "value": "Marshallese", "label": "Kajin M̧ajeļ" },
    { "code": "mn", "value": "Mongolian", "label": "монгол" },
    { "code": "na", "value": "Nauru", "label": "Ekakairũ Naoero" },
    { "code": "nv", "value": "Navajo, Navaho", "label": "Diné bizaad, Dinékʼehǰí" },
    { "code": "nb", "value": "Norwegian Bokmål", "label": "Norsk bokmål" },
    { "code": "nd", "value": "North Ndebele", "label": "isiNdebele" },
    { "code": "ne", "value": "Nepali", "label": "नेपाली" },
    { "code": "ng", "value": "Ndonga", "label": "Owambo" },
    { "code": "nn", "value": "Norwegian Nynorsk", "label": "Norsk nynorsk" },
    { "code": "no", "value": "Norwegian", "label": "Norsk" },
    { "code": "ii", "value": "Nuosu", "label": "ꆈꌠ꒿ Nuosuhxop" },
    { "code": "nr", "value": "South Ndebele", "label": "isiNdebele" },
    { "code": "oc", "value": "Occitan", "label": "Occitan" },
    { "code": "oj", "value": "Ojibwe, Ojibwa", "label": "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
    { "code": "cu", "value": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic", "label": "ѩзыкъ словѣньскъ" },
    { "code": "om", "value": "Oromo", "label": "Afaan Oromoo" },
    { "code": "or", "value": "Oriya", "label": "ଓଡ଼ିଆ" },
    { "code": "os", "value": "Ossetian, Ossetic", "label": "ирон æвзаг" },
    { "code": "pa", "value": "Panjabi, Punjabi", "label": "ਪੰਜਾਬੀ, پنجابی‎" },
    { "code": "pi", "value": "Pāli", "label": "पाऴि" },
    { "code": "fa", "value": "Persian", "label": "فارسی" },
    { "code": "pl", "value": "Polish", "label": "polski" },
    { "code": "ps", "value": "Pashto, Pushto", "label": "پښتو" },
    { "code": "pt", "value": "Portuguese", "label": "Português" },
    { "code": "qu", "value": "Quechua", "label": "Runa Simi, Kichwa" },
    { "code": "rm", "value": "Romansh", "label": "rumantsch grischun" },
    { "code": "rn", "value": "Kirundi", "label": "kiRundi" },
    { "code": "ro", "value": "Romanian, Moldavian, Moldovan", "label": "română" },
    { "code": "ru", "value": "Russian", "label": "русский язык" },
    { "code": "sa", "value": "Sanskrit (Saṁskṛta)", "label": "संस्कृतम्" },
    { "code": "sc", "value": "Sardinian", "label": "sardu" },
    { "code": "sd", "value": "Sindhi", "label": "सिन्धी, سنڌي، سندھی‎" },
    { "code": "se", "value": "Northern Sami", "label": "Davvisámegiella" },
    { "code": "sm", "value": "Samoan", "label": "gagana faa Samoa" },
    { "code": "sg", "value": "Sango", "label": "yângâ tî sängö" },
    { "code": "sr", "value": "Serbian", "label": "српски језик" },
    { "code": "gd", "value": "Scottish Gaelic; Gaelic", "label": "Gàidhlig" },
    { "code": "sn", "value": "Shona", "label": "chiShona" },
    { "code": "si", "value": "Sinhala, Sinhalese", "label": "සිංහල" },
    { "code": "sk", "value": "Slovak", "label": "slovenčina" },
    { "code": "sl", "value": "Slovene", "label": "slovenščina" },
    { "code": "so", "value": "Somali", "label": "Soomaaliga, af Soomaali" },
    { "code": "st", "value": "Southern Sotho", "label": "Sesotho" },
    { "code": "es", "value": "Spanish; Castilian", "label": "español, castellano" },
    { "code": "su", "value": "Sundanese", "label": "Basa Sunda" },
    { "code": "sw", "value": "Swahili", "label": "Kiswahili" },
    { "code": "ss", "value": "Swati", "label": "SiSwati" },
    { "code": "sv", "value": "Swedish", "label": "svenska" },
    { "code": "ta", "value": "Tamil", "label": "தமிழ்" },
    { "code": "te", "value": "Telugu", "label": "తెలుగు" },
    { "code": "tg", "value": "Tajik", "label": "тоҷикӣ, toğikī, تاجیکی‎" },
    { "code": "th", "value": "Thai", "label": "ไทย" },
    { "code": "ti", "value": "Tigrinya", "label": "ትግርኛ" },
    { "code": "bo", "value": "Tibetan Standard, Tibetan, Central", "label": "བོད་ཡིག" },
    { "code": "tk", "value": "Turkmen", "label": "Türkmen, Түркмен" },
    { "code": "tl", "value": "Tagalog", "label": "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
    { "code": "tn", "value": "Tswana", "label": "Setswana" },
    { "code": "to", "value": "Tonga (Tonga Islands)", "label": "faka Tonga" },
    { "code": "tr", "value": "Turkish", "label": "Türkçe" },
    { "code": "ts", "value": "Tsonga", "label": "Xitsonga" },
    { "code": "tt", "value": "Tatar", "label": "татарча, tatarça, تاتارچا‎" },
    { "code": "tw", "value": "Twi", "label": "Twi" },
    { "code": "ty", "value": "Tahitian", "label": "Reo Tahiti" },
    { "code": "ug", "value": "Uighur, Uyghur", "label": "Uyƣurqə, ئۇيغۇرچە‎" },
    { "code": "uk", "value": "Ukrainian", "label": "українська" },
    { "code": "ur", "value": "Urdu", "label": "اردو" },
    { "code": "uz", "value": "Uzbek", "label": "zbek, Ўзбек, أۇزبېك‎" },
    { "code": "ve", "value": "Venda", "label": "Tshivenḓa" },
    { "code": "vi", "value": "Vietnamese", "label": "Tiếng Việt" },
    { "code": "vo", "value": "Volapük", "label": "Volapük" },
    { "code": "wa", "value": "Walloon", "label": "Walon" },
    { "code": "cy", "value": "Welsh", "label": "Cymraeg" },
    { "code": "wo", "value": "Wolof", "label": "Wollof" },
    { "code": "fy", "value": "Western Frisian", "label": "Frysk" },
    { "code": "xh", "value": "Xhosa", "label": "isiXhosa" },
    { "code": "yi", "value": "Yiddish", "label": "ייִדיש" },
    { "code": "yo", "value": "Yoruba", "label": "Yorùbá" },
    { "code": "za", "value": "Zhuang, Chuang", "label": "Saɯ cueŋƅ, Saw cuengh" }

);

exports.language_list = language_arr;