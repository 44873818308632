import React, {lazy,Suspense,useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha"

import { Link } from 'react-router-dom';

import intlTelInput from 'intl-tel-input';
import $ from 'jquery';
import { setCookie, getCookie } from '../common_component/cookie.js';

function Signup() {
	let modal=2;
	const [RedirectState, setRedirectState] = useState(2);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	const [captchaToken, setCaptchaToken] = useState("");
	const [phone_input, setphone_input] = useState({});
	
	const [value, setValue] = useState();
	
	function onChange(value)
	{
		setCaptchaToken(value)
	}
	
	
	
	// useEffect(() => {
		
	// 		var se = document.createElement('script');
	// 		se.src = "/assets/js/custom/authentication/sign-up/general.js";
	// 		document.body.appendChild(se);
			
	// },[])

 
	const register =(e)=>{
		
			e.preventDefault();
			var object = {};
			object.email = document.getElementById("email").value;
			object.password = document.getElementById("password").value;
			object.fname = document.getElementById("fname").value;
			object.lname = document.getElementById("lname").value;
			object.cpassword = document.getElementById("cpassword").value;
			//object.orgname = document.getElementById("orgname").value;
			//object.orgdomain = document.getElementById("orgdomain").value.trim().toLowerCase();
			// object.domain = document.getElementById("domain").value;
			object.service="support";
			object.phone = document.getElementById("phone").value;
			object.token = captchaToken;
			// if(phone_input.getNumber().startsWith("+") )
			// {
			// 	object.phone = phone_input.getNumber();
			// }
			// else{
			// 	var countryData = phone_input.getSelectedCountryData();
			// 	object.phone = "+"+countryData.dialCode+phone_input.getNumber();
			// }
			// console.log(phoneNumber);
			// console.log(object);
			var nexp=/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
			if(object.fname=="")
			{
				//console.log("1");
				swal("Warning!", "First Name cannot be a empty", "warning");
				return false;
			}
			else if(!isNaN(object.fname))
			{
				//console.log("2");
				swal("Warning!", "First Name cannot be a number", "warning");
				return false;
			}
			else if (object.fname.match(nexp))
			{
				//console.log("3");
				swal("Warning!", "First Name cannot contain symbols ", "warning");
				return false;
			}

			if(object.lname=="")
			{
				//console.log("4");
				swal("Warning!", "Last Name cannot be a empty", "warning");
				return false;
			}
			else if(!isNaN(object.lname))
			{
				//console.log("5");
				swal("Warning!", "Last Name cannot be a number", "warning");
				return false;
			}
			else if (object.lname.match(nexp))
			{
				//console.log("6");
				swal("Warning!", "Last Name cannot contain symbols ", "warning");
				return false;
			}
			
			
			
			// var nexp2=/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/; //regex without NOT operator needed in match
			/*var nexp2=/^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 &$|~!@#()_.;,:*-+%]*)?$/; //NOT operator needed in match
			if(object.orgname=="")
			{
				//console.log("1");
				swal("Warning!", "Organization Name cannot be empty", "warning");
				return false;
			}
			else if(!isNaN(object.orgname))
			{
				//console.log("2");
				swal("Warning!", "Organization Name cannot be a number", "warning");
				return false;
			}
			else if (!object.orgname.match(nexp2))
			{
				//console.log("3");
				swal("Warning!", "Organization Name cannot contain symbols ", "warning");
				return false;
			}

			var exp3 = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9\/\-.:]*)?$/;
			//only single word without space, numbers or special characters
			if(object.orgdomain=="")
			{
				//console.log("7");
				swal("Warning!", "Please provide a Organization domain name", "warning");
				return false;
			}
			else if (!object.orgdomain.match(exp3))
			{
				swal("Warning!", "Certain symbols are not allowed in domain name", "warning");
				return false;
			}
			*/
			
			// var exp4= /^((?!-)[A-Za-z0-9-]{1, 63}(?<!-)\\.)+[A-Za-z]{2, 6}$/;
			// var exp4= /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;
			// //only domain.com
			// if(object.orgdomain=="")
			// {
			// 	//console.log("7");
			// 	swal("Warning!", "Please provide a Organization domain", "warning");
			// 	return false;
			// }
			// else if (!object.orgdomain.match(exp4))
			// {
			// 	swal("Warning!", "Please provide a valid Organization domain", "warning");
			// 	return false;
			// }

			// var exp=/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			var exp=/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
			if(object.email=="")
			{
				//console.log("7");
				swal("Warning!", "Please provide an email", "warning");
				return false;
			}
			else if (!object.email.match(exp))
			{
				//console.log("8");
				swal("Warning!", "Please provide a valid email", "warning");
				return false;
			}
			/*else if(!object.email.toLowerCase().includes(object.orgdomain)){
				swal("Warning!", "Your email doesn't match your Organization domain", "warning");
				return false;
			}*/

			//var exp2=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%^&*])[a-zA-Z\d@.#$%^&*]{8,}$/;
			var exp2=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!$%^&*-]).{8,}$/;
			if(object.password=="")
			{
				//console.log("9");
				swal("Warning!", "Password cannot be empty", "warning");
				return false;
			}
			else if(object.password.length < 8)
			{
				swal("Warning!", "Password must have 8 characters.", "warning");
				return false;
			}
			// else if (object.password.includes("@") || object.password.includes("."))
			// {
			// 	//console.log("11");
			// 	swal("Warning!", "Password cannot contain '@' and '.' symbols within it.", "warning");
			// 	return false;
			// }
			// else if (!object.password.match(exp2))
			// {
			// 	swal("Warning!", "Password is not valid, try with a different password.", "warning");
			// 	return false;
			// }
			
			
			// if(phone_input.getNumber() == "")
			// {
			// 	swal("Warning!", "Phone number cannot be empty.", "warning");
			// 	return false;
			// }
			// else if(isNaN(object.phone) )
			// {
			// 	//console.log("13");
			// 	swal("Oops!", "Please enter a valid phone number", "error");
			// 	return false;
			// }
			
			// else if(object.phone.length != 10 )
			// {
			// 	//console.log("13");
			// 	swal("Oops!", "Please enter 10 digit phone number", "error");
			// 	return false;
			// }
			
			if(object.cpassword=="")
			{
				//console.log("14");
				swal("Warning!", "Please confirm your password by re-typing.", "warning");
				return false;
			}
			else if (object.cpassword != object.password)
			{
				//console.log("15");
				swal("Warning!", "New Password and Confirm Password is not matching.", "warning");
				return false;
			}
			
			if(document.getElementById("agreement").checked == false)
			{
				//console.log("16");
				swal("Warning!", "Please check our terms and condition.", "warning");
				return false;
			}
			
			if(object.token=="")
			{
				//console.log("17");
				swal("Warning!", "Please Verify the captcha", "warning");
				return false;
			}
			else if(!isNaN(object.token))
			{
				//console.log("18");
				swal("Warning!", "Please Verify the captcha", "warning");
				return false;
			}
			
			// if(e.target.id == "kt_sign_up_submit")
			// {
			// 	e.target.setAttribute("data-kt-indicator", "on");
			// 	e.target.disabled = true;
			// }
			// else
			// {
			// 	document.getElementById("kt_sign_up_submit").setAttribute("data-kt-indicator", "on");
			// 	document.getElementById("kt_sign_up_submit").disabled = true;
			// }
			// console.log(object)
			setMainSubmitButtonState(true)
			var headers = {
			  headers: {
				"Content-Type": "application/json",
				"Accept": "*/*"
			  }
			}
			// console.log(object)
		axios.post(`${process.env.REACT_APP_SERVER_URL}/register`,object, headers).then((res) => {
			setMainSubmitButtonState(false)
				if(res.data.operation == "domain_exist"){
					swal("Oops!", "Domain already taken", "warning").then((res) => {
						window.location.reload();
					});
					// return false;
				}
				else if(res.data.operation == "success")
				{
					// console.log("success area")
					swal("Great!", "Registration Successful ", "success",{
							buttons: {
										continue: {
											text: "OK",
											value: "continue",
										},
										}},).then((result) => {
							//console.log(result);
							switch (result) {
								case "continue":
									window.location=`/login`;
									break;
								default:
									window.location=`/login`;
									break;
							}
					});
				}
				else
				{
					if(res.data.message != "danger")
					{
						// console.log("else part 1")

						swal("Oops!", res.data.message, "error").then((res) => {
							//window.location.reload();
						});
					}
					else
					{
						// console.log("else part 2")

						swal("Oops!", "something went wrong", "error").then((res) => {
							//window.location.reload();
						});
					}
				}
			})
			.catch(function (error) {
				// console.log("register api call exeption")
				swal("Oops!", "something went wrong", "error")
				.then((res) => {
							window.location.reload();
				});
			});
	}
	useEffect(() => {
		function loadScripts(){
			
        var se = document.createElement('script');
        se.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js";
        se.type="text/javascript";
        document.body.appendChild(se);
		};
		loadScripts();
	},[])

// function international_phone_number(){
// 	const phoneInputField = document.querySelector("#phone");

// 			const phoneInput = window.intlTelInput(phoneInputField, {
// 				initialCountry: "auto", 
// 				geoIpLookup: function(success, failure) {
// 					$.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
// 					  var countryCode = (resp && resp.country) ? resp.country : "us";
// 					  success(countryCode);
// 					});
// 				  },
// 				utilsScript:
// 				"https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
// 			});
// 			setphone_input(phoneInput)
// 			//console.log(getIp);
// 	}
// 	 
// 	window.addEventListener("load", function(){
// 		if(modal==2){
// 	        international_phone_number()
// 		}
// 	}, false)


	return (
		<>
		{
			(RedirectState == 1) ? ((getCookie("lastpage") != "") ? window.location.href = getCookie("lastpage") : window.location.href = "/"
			)
			: 
			null
		}
		<div className="bg-dark header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed" style={{"--kt-toolbar-height":"55px", "--kt-toolbar-height-tablet-and-mobile":"55px"}} >
		<div className="d-flex flex-column flex-root">
			<div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{"background-size1": "100% 50%" , "background-image" : "url(assets/media/misc/outdoor.png)"}}>
				<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
					<a className="mb-12">
						{/*<img alt="Logo" src="https://cdn-adocrm.s3.amazonaws.com/static/ado-crm-logo.png" className="h-45px" />*/}
					</a>
					<div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
						<form className="form w-100" novalidate="novalidate" id="kt_sign_up_form" onSubmit={(e) => {register(e)}}>
							<div className="mb-10 text-center">
								<h1 className="text-dark mb-3">Create an Account</h1>
								<div className="text-gray-400 fw-bold fs-4">Already have an account?
								<Link to="/login" className="link-primary fw-bolder"> Sign in here</Link></div>
							</div>
							
							<div className="d-flex align-items-center mb-10">
								<div className="border-bottom border-gray-300 mw-50 w-100"></div>
								<span className="fw-bold text-gray-400 fs-7 mx-2">OR</span>
								<div className="border-bottom border-gray-300 mw-50 w-100"></div>
							</div>
							
							<div className="row fv-row mb-7">
								<div className="col-xl-6">
									<label className="form-label fw-bolder text-dark fs-6">First Name</label>
									<input className="form-control form-control-lg form-control-solid" type="text" placeholder="First" name="first-name" id="fname" autocomplete="off" />
								</div>
								<div className="col-xl-6">
									<label className="form-label fw-bolder text-dark fs-6">Last Name</label>
									<input className="form-control form-control-lg form-control-solid" type="text" placeholder="Last" id="lname" name="last-name" autocomplete="off" />
								</div>
							</div>
							
							<div className="row fv-row mb-7">
								<div className="col-xl-6">
									<label className="form-label fw-bolder text-dark fs-6">Email</label>
									<input className="form-control form-control-lg form-control-solid" type="email" placeholder="example: richard@subway.com" id="email" name="email" autocomplete="off" />
								</div>
								<div className="col-xl-6">
									<label className="form-label fw-bolder text-dark fs-6">Phone Number</label>
									<input className="form-control form-control-lg form-control-solid" type="text" placeholder="enter number" id="phone"  name="phone" autocomplete="off" />
								</div>
							</div>
							{/*<div className="row fv-row mb-7">
								<div className="mb-1">
									<label className="form-label fw-bolder text-dark fs-6">Organization Name</label>
									<input className="form-control form-control-lg form-control-solid" type="text" placeholder="example: Subway IP LLC" name="org-name" id="orgname" autocomplete="off" />
								</div>
							</div>
							<div className="row fv-row mb-7">
								<div className="mb-1">
									<label className="form-label fw-bolder text-dark fs-6">Organization Domain Name</label>
									<input className="form-control form-control-lg form-control-solid" type="text" placeholder="example: subway" id="orgdomain" name="org-domain" autocomplete="off" />
								</div>
								 <div className="col-xl-6">
									<label className="form-label fw-bolder text-dark fs-6">Domain</label>
									<input className="form-control form-control-lg form-control-solid" type="text" placeholder="example: kredily.com" id="domain" name="domain" autocomplete="off" />
								</div> 
							</div>*/}
							
							<div className="mb-10 fv-row" data-kt-password-meter="true">
								<div className="mb-1">
									<label className="form-label fw-bolder text-dark fs-6">Password</label>
									<div className="position-relative mb-3">
										<input className="form-control form-control-lg form-control-solid" type="password" placeholder="" name="password" id="password" autocomplete="off" />
										<span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
											<i className="bi bi-eye-slash fs-2"></i>
											<i className="bi bi-eye fs-2 d-none"></i>
										</span>
									</div>
									
								</div>
								<div className="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
							</div>
							<div className="fv-row mb-5">
								<label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
								<input className="form-control form-control-lg form-control-solid" type="password" id="cpassword" placeholder="" name="confirm-password" autocomplete="off" />
							</div>
							<div className="fv-row mb-10">
								<label className="form-check form-check-custom form-check-solid">
									<input className="form-check-input" id="agreement" type="checkbox" name="toc" value="1" />
									<span className="form-check-label fw-bold text-gray-700 fs-6">I Agree &amp;
									<a className="ms-1 link-primary">Terms and conditions</a>.</span>
								</label>
							</div>
							<div className="text-center">
								<ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`} onChange={onChange} className="btn btn-lg" size="compact" />
								<br />
								<button type="button" id="kt_sign_up_submit" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} className="btn btn-lg btn-primary"  onClick={(e) => {register(e)}}>
									<span className="indicator-label">Submit</span>
									<span className="indicator-progress">Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
								</button>
							</div>
						</form>
					</div>
				</div>
				<div className="d-flex flex-center flex-column-auto p-10">
					<div className="d-flex align-items-center fw-bold fs-6">
						
					</div>
				</div>
			</div>
		</div>
		</div>
		</>
	);
}
export default Signup;