import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';

import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

export const ViewProfiles = (props) => {
    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);
    const [user, setUser] = useState({});
    // const [PurchaseDetails, setPurchaseDetails] = useState([]);
    const [state, setstate] = useState(2);

    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);

    const [Permission, setpermission] = useState([]);
    const [status, setstatus] = useState("");

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [organization_id, setOrganization_id] = useState("");
    const [organization_list, setorganization_list] = useState([]);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    const [object, setobject] = useState({});
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    const [Id, setId] = useState("");
    const [userlist, setuserList] = useState([]);
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Mobile, setMobile] = useState("");
    const [Employee_id, setEmployee_id] = useState("");
    const [Gender, setGender] = useState("");
    const [Designation, setDesignation] = useState("");
    const [Skip_mail, setSkip_mail] = useState("");
    const [Dob_date, setDob_date] = useState("");
    const [Joining_date, setJoining_date] = useState("");
    const [Department, setDepartment] = useState("");
    const [Submit_to, setSubmit_to] = useState("");
    const [DepartmentList, setDepartmentList] = useState([]);
    const [Policy, setPolicy] = useState("");
    // const [PolicyList, setPolicyList] = useState([]);
    const [Profile, setProfile] = useState("");
    const [UserProfileList, setUserProfileList] = useState([]);
    const [GenderList, setGenderList] = useState([{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }]);
    const [editmodal, setEditModal] = useState([]);
    const [DateFormat, setDateFormat] = useState("");

    var page_name = "view-profile";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);
                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                window.location.href = "/login"
            });
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (temp == 1) break;

                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);

                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);

            }
        }
        organization_id != "" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer) => {
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);


    useEffect(() => {
        // async function getbasecurrency() {

        //     var object = {};
        //     object.email = user.email;

        //     const response = await fetch(
        //         `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        //     );
        //     const body = await response.json();
        //     if(body.length>0)
        //     {
        //         setDateFormat(body[0].date_format);
        //     }
        // }
        // if(read || write)
        // {
        //     (user.email && visibility) && getbasecurrency();
        // }
        async function getProfiledetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-profile-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support", "id": props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            setEditModal(body);
            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }
        }
        if (read || write && organization_id != "") {
            user.email && visibility && getProfiledetails();
        }

    }, [user, visibility, read, write, organization_id])

    // useEffect(() => {
    //     async function getbasecurrency() {

    //         var object = {};
    //         object.email = user.email;

    //         const response = await fetch(
    //             `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
    //         );
    //         const body = await response.json();
    //         if(body.length>0)
    //         {
    //             setDateFormat(body[0].date_format);
    //         }
    //     }
    //     if(read || write)
    //     {
    //         (user.email && visibility) && getbasecurrency();
    //     }
    //     async function getUserProfiledetails() {
    //         var object = {};
    //         object.email = user.email;

    //         const response = await fetch(
    //             `${process.env.REACT_APP_SERVER_URL}/get-profiles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
    //         );
    //         const body = await response.json();
    //         var temp_dropdown = [];
    //         body.map((obj) => {
    //             if (obj.add_status === "Active") {
    //                 var temp_obj = {};
    //                 temp_obj.label = obj.user_Profile;
    //                 temp_obj.value = obj.user_Profile_id;
    //                 temp_dropdown.push(temp_obj);
    //             }
    //         })
    //         setUserProfileList(temp_dropdown);
    //     }
    //     if (read || write) {
    //         user.email && visibility && getUserProfiledetails();
    //     }

    //     async function getuserdetails() {
    //         var object = {};
    //         object.email = user.email;

    //         const response = await fetch(
    //             `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
    //         );
    //         const body = await response.json();

    //         if (body.length > 0) {
    //             var temp_dropdown = [];
    //             body.map((obj) => {
    //                 if (obj.log_status === "Active") {
    //                     var temp_obj = {};
    //                     temp_obj.label = obj.name;
    //                     temp_obj.value = obj.user_id;
    //                     temp_dropdown.push(temp_obj);
    //                 }
    //             })
    //             //setEditModal(body);
    //             setuserList(temp_dropdown);
    //         }
    //         else {
    //             setstate(0);
    //         }
    //     }
    //     if (read || write && organization_id != "") {
    //         user.email && visibility && getuserdetails();
    //     }

    //     async function getdeparmentdetails() {
    //         var object = {};
    //         object.email = user.email;

    //         const response = await fetch(
    //             `${process.env.REACT_APP_SERVER_URL}/department-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
    //         );
    //         const body = await response.json();
    //         var temp_dropdown = [];
    //         body.map((obj) => {
    //             if (obj.status === "Active") {
    //                 var temp_obj = {};
    //                 temp_obj.label = obj.department_name;
    //                 temp_obj.value = obj.department_id;
    //                 temp_dropdown.push(temp_obj);
    //             }
    //         })
    //         setDepartmentList(temp_dropdown);
    //     }
    //     if (read || write) {
    //         user.email && visibility && getdeparmentdetails();
    //     }

    //     async function getUserPolicydetails() {
    //         var object = {};
    //         object.email = user.email;

    //         const response = await fetch(
    //             `${process.env.REACT_APP_SERVER_URL}/get-travel-policy`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id,"service":"support"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
    //         );
    //         const body = await response.json();
    //         var temp_dropdown = [];
    //         body.map((obj) => {
    //             if (obj.add_status === "Active") {
    //                 var temp_obj = {};
    //                 temp_obj.label = obj.policy_name;
    //                 temp_obj.value = obj.unique_id;
    //                 temp_dropdown.push(temp_obj);
    //             }
    //         })
    //         setPolicyList(temp_dropdown);
    //     }
    //     if (read || write) {
    //         user.email && visibility && getUserPolicydetails();
    //     }

    // }, [user, visibility, read, write, organization_id])


    // const deleteStatus = (id) => {
    //     for (let i = 0; i < editmodal.length; i++) {
    //         if (id == editmodal[i].user_Profile_id) {
    //             setId(editmodal[i].user_Profile_id)
    //             setstatus("Deleted");
    //         }
    //     }
    // }

    // const updateStatus = (id) => {
    //     for (let i = 0; i < editmodal.length; i++) {
    //         if (id == editmodal[i].unique_id) {
    //             setId(editmodal[i].unique_id)
    //             setstatus(editmodal[i].log_status);
    //         }
    //     }
    // }

    // const editDetails = (id) => {
    //     //console.log(editmodal[0].user_details)
    //     for (let i = 0; i < editmodal[0].user_details.length; i++) {
    //         if (id == editmodal[0].user_details[i].unique_id) {
    //             console.log(editmodal[0].user_details[i])
    //             setId(editmodal[0].user_details[i].unique_id)
    //             setName(editmodal[0].user_details[i].name);
    //             setEmail(editmodal[0].user_details[i].user_id)
    //             // setMobile(editmodal[0].user_details[i].support.mobile)
    //             // setEmployee_id(editmodal[0].user_details[i].support.Employee_id)
    //             // setGender(editmodal[0].user_details[i].support.gender)
    //             // setDesignation(editmodal[0].user_details[i].support.designation)
    //             // setProfile(editmodal[0].user_details[i].support.Profile)
    //             // //setSkip_mail(editmodal[0].user_details[i].add_status)
    //             // setpermission(editmodal[0].user_details[i].support.permission)
    //             // setDob_date(editmodal[0].user_details[i].support.Date_of_birth)
    //             // setJoining_date(editmodal[0].user_details[i].support.Joining_date)
    //             // setDepartment(editmodal[0].user_details[i].support.department)
    //             // setSubmit_to(editmodal[0].user_details[i].support.Submit_To)
    //         }
    //     }
    // }

    // const UpdateUser = (e) => {
    //     e.preventDefault();
    //     var object = {};
    //     object.organization_id = organization_id;
    //     object.id = Id;
    //     object.name = Name;
    //     object.email_id = Email;
    //     object.mobile = Mobile;
    //     object.Employee_id = Employee_id;
    //     object.Submit_to = Submit_to;
    //     object.Profile = Profile;
    //     object.service = "support";
    //     object.permission = Permission;
    //     object.Dob_date = Dob_date;
    //     object.department = Department;
    //     object.Joining_date = Joining_date;
    //     object.Gender = Gender;
    //     object.Policy = Policy;
    //     object.Skip_mail = Skip_mail;
    //     object.Designation = Designation;
    //     object.page_name = page_name;

    //     if (object.name == "") {
    //         swal("Warning!", "Name cannot be a empty", "warning");
    //         return false;
    //     }
    //     var exp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    //     if (object.email_id == "") {
    //         //console.log("7");
    //         swal("Warning!", "Please provide an email", "warning");
    //         return false;
    //     }
    //     else if (!object.email_id.match(exp)) {
    //         //console.log("8");
    //         swal("Warning!", "Please provide a valid email", "warning");
    //         return false;
    //     }
    //     var oname = editmodal.filter((item) => { return item.unique_id !== object.email_id })
    //     for (var i = 0; i < oname.length; ++i) {
    //         if (oname[i].label == object.email_id) {
    //             swal("Oops!", "This email id already connected to your organization, try different one!", "error");
    //             return false;
    //         }
    //     }
    //     if (object.Profile == "") {
    //         swal("Warning!", "Profile field cannot be a empty", "warning");
    //         return false;
    //     }
    //     if (object.Policy == "") {
    //         swal("Warning!", "Policy field cannot be a empty", "warning");
    //         return false;
    //     }
    //     setMainSubmitButtonState(true)
    //     var headers = {
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Accept": "*/*",
    //             "token": getCookie("access_token")
    //         }
    //     }
    //     axios.post(`${process.env.REACT_APP_SERVER_URL}/update-user`, object, headers).then((res) => {
    //         setMainSubmitButtonState(false)
    //         if (res.data && res.data.operation == "success") {
    //             swal("Great!", "user updated successfully!", "success").then((value) => {
    //                 window.location.reload();
    //             });
    //         }
    //         else if (res.data.operation === "same_user_name") {
    //             swal("Oops!", "Use a different User name!!", "error");
    //         }
    //         else {
    //             swal("Oops!", "Something went wrong!", "error");
    //         }
    //     }).catch(function (error) {
    //         console.log(error)
    //     });

    // }

    // const submitStatus = (e) => {
    //     e.preventDefault();

    //     var values = { "id": Id, "status": status, "page_name": page_name, "organization_id": organization_id };
    //     setMainSubmitButtonState(true)
    //     var headers = {
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Accept": "*/*",
    //             "token": getCookie("access_token")
    //         }
    //     }

    //     axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-user`, values, headers).then((res) => {
    //         setMainSubmitButtonState(false)
    //         if (res.data && res.data.operation == "success") {
    //             swal("Great!", "Status changed successfully!", "success").then((value) => {
    //                 window.location.reload();
    //             });
    //         }
    //         else {
    //             swal("Oops!", "Something went wrong!", "error");
    //         }
    //     });

    // }

    return (
        <>
         


            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
                redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
                    <Redirect to={{ pathname: "/login" }} />
                </> : null)
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">View Profiles
                            <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>

                <div id="kt_app_content" class="app-content flex-column-fluid">
                    <div id="kt_app_content_container" class="app-container container-xxl">
                        {state == 1 ?
                            <div class="d-flex flex-column flex-lg-row">
                                <div class="flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10">
                                    <div class="card card-flush">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <h2 class="mb-0">{editmodal[0].user_profile}</h2>
                                            </div>
                                        </div>
                                        <div class="card-body pt-0">
                                            <div class="d-flex flex-column text-gray-600">
                                                {editmodal[0].profile_description != "" &&
                                                    <div class="d-flex align-items-center py-2">
                                                        <span class="bullet bg-primary me-3"></span>{editmodal[0].profile_description}
                                                    </div>
                                                }
                                            </div>
                                        </div> 
                                        <div class="card-footer pt-0">

                                            <Link to={`/update-profile/${props.match.params.id}`} className="btn btn-light btn-active-primary me-2">Edit Profile</Link>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex-lg-row-fluid ms-lg-10">
                                    <div class="card card-flush mb-6 mb-xl-9">
                                        <div class="card-header pt-5">
                                            <div class="card-title">
                                                <h2 class="d-flex align-items-center">Users Assigned
                                                    <span class="text-gray-600 fs-6 ms-1">({editmodal[0].user_details.length})</span></h2>
                                            </div>
                                            <div class="card-toolbar">
                                                <a href="/add-agents">
                                                    <button type="button" class="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_permission">
                                                        <span class="svg-icon svg-icon-3">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        Add User</button></a>
                                            </div>
                                            {/*<div class="card-toolbar">
                                            <div class="d-flex align-items-center position-relative my-1" data-kt-view-Profiles-table-toolbar="base">
                                                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                
                                                <input type="text" data-kt-Profiles-table-filter="search" class="form-control form-control-solid w-250px ps-15" placeholder="Search Users" />
                                            </div>
                                            
                                            <div class="d-flex justify-content-end align-items-center d-none" data-kt-view-Profiles-table-toolbar="selected">
                                                <div class="fw-bold me-5">
                                                <span class="me-2" data-kt-view-Profiles-table-select="selected_count"></span>Selected</div>
                                                <button type="button" class="btn btn-danger" data-kt-view-Profiles-table-select="delete_selected">Delete Selected</button>
                                            </div>   
                                        </div>*/}
                                        </div>
                                        <div class="card-body pt-0">
                                            <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0" id="kt_Profiles_view_table">
                                                <thead>
                                                    <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                                        <th class="min-w-50px">Name</th>
                                                        <th class="min-w-125px">Joined Date</th>
                                                        <th class="min-w-125px">Status</th>
                                                        {/* <th class="text-end min-w-70px">Actions</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody class="fw-semibold text-gray-600">
                                                    {editmodal[0].user_details.length > 0 && editmodal[0].user_details.map((obj, index) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td class="d-flex align-items-center">
                                                                        <div class="d-flex flex-column">
                                                                            <a href="../../demo1/dist/apps/user-management/users/view.html" class="text-gray-800 text-hover-primary mb-1">{obj.name}</a>
                                                                            <span>{obj.user_id}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>{moment(obj.log_date_time).format('llll l')}</td>
                                                                    <td><span class="badge py-3 px-4 fs-7 badge-light-success">{obj.log_status}</span></td>
                                                                    {/* <td class="text-end">
                                                                        <div class="dropdown">
                                                                            {
                                                                                write ?
                                                                                    <>
                                                                                        <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                            <i className="fas fa-bars px-0"></i>
                                                                                        </button>
                                                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                            <li><a onClick={(e) => { editDetails(obj.unique_id) }} data-bs-toggle="modal" data-bs-target="#EditDetails" class="dropdown-item">Edit details</a></li>
                                                                                            <li><Link to={`/update-user-profile/${obj.unique_id}`} class="dropdown-item">Edit Profile information</Link></li>
                                                                                            <li><a onClick={(e) => { updateStatus(obj.unique_id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Change status</a></li>
                                                                                            <li><a onClick={(e) => { deleteStatus(obj.unique_id) }} data-bs-toggle="modal" data-bs-target="#deleteStatus" class="dropdown-item" href="#">Delete</a></li>
                                                                                            {obj.message_flag=="later"} && <li><a onClick={(e) => { sendInvitation(obj.unique_id1) }} class="dropdown-item">Send Invitation</a></li>

                                                                                        </ul>
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </td> */}
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                {
                                    state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <>
                                            <div className="card-body pt-0">
                                                <div className="text-gray-500 fw-bold fs-5 mb-5">View Profile Details</div>

                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-bold">
                                                            <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                            <div className="fs-6 text-gray-600"> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }
                            </>
                        }
                    </div>
                </div>

                {/*<div className="card">

                    <div className="card-body pt-6">
                        {
                            data.length > 0 ?
                                <>
                                    <MUIDataTable
                                        title={"Profile Details"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />

                                </>
                                :
                                <>
                                    {
                                        state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            <>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5">Show Policies</div>

                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                <div className="fs-6 text-gray-600"> </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </>
                                    }
                                </>
                        }
                    </div>
                </div>*/}
            </div>


            {/* <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    Current Status is : {(status == "Active" || status == "ACTIVE") ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                    <h3>Are you sure about changing status?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />

                                {(status == "Active" || status == "ACTIVE") ? <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} >Deactivate</button> : <button type="button" className="btn btn-success" onClick={(e) => { submitStatus(e); }} >Activate</button>}
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}



            {/* <div className="modal fade" id="EditDetails" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-750px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit Details</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="400px">
                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Name</label>
                                            <input id="department_name" type="text" className="form-control form-control-solid " placeholder="" name="department-name" value={Name} onChange={(e) => { setName(e.target.value) }} readOnly={write ? false : true} />
                                        </div>
                                        <div className="col-md-6 fv-row">
                                            <label className=" fs-5 fw-bold mb-2">Employee ID</label>
                                            <input id="department_name" type="text" className="form-control form-control-solid " placeholder="" name="department-name" value={Employee_id} onChange={(e) => { setEmployee_id(e.target.value) }} readOnly={write ? false : true} />
                                        </div>

                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Email Address</label>
                                            <input id='category_code' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Email} onChange={(e) => { setEmail(e.target.value) }} readOnly={write ? false : true} />
                                        </div>
                                        <div className="col-md-6 fv-row">
                                            <label className=" fs-5 fw-bold mb-2">Mobile</label>
                                            <input id='category_code' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Mobile} onChange={(e) => { setMobile(e.target.value) }} readOnly={write ? false : true} />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                <div className="col-md-12 fv-row">
                                    <label className="required fs-5 fw-bold mb-2">Profile</label>
                                    <Select
                                        name="category"
                                        id="category"
                                        className="form-control form-control-solid"
                                        options={UserProfileList}
                                        onChange={(event) => { setProfile(event.value) }}
                                        value={UserProfileList.filter(option => option.value == Profile).length != 0 && UserProfileList.filter(option => option.value == Profile)[0]}
                                        placeholder="--Choose a Profile--"
                                    />
                                </div>
                            </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className=" fs-5 fw-bold mb-2">Submits To</label>
                                            <Select
                                                name="category"
                                                id="category"
                                                className="form-control form-control-solid"
                                                options={userlist}
                                                onChange={(event) => { setSubmit_to(event.value) }}
                                                value={userlist.filter(option => option.value == Submit_to).length != 0 && userlist.filter(option => option.value == Submit_to)[0]}
                                                placeholder="--Select--"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className=" fs-5 fw-bold mb-2">Department Name</label>
                                            <Select
                                                name="category"
                                                id="category"
                                                className="form-control form-control-solid"
                                                options={DepartmentList}
                                                onChange={(event) => { setDepartment(event.value) }}
                                                value={DepartmentList.filter(option => option.value == Department).length != 0 && DepartmentList.filter(option => option.value == Department)[0]}
                                                placeholder="--Choose a Department--"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">Policy</label>
                                        <Select
                                            name="category"
                                            id="category"
                                            className="form-control form-control-solid"
                                            // options={PolicyList}
                                            onChange={(event) => { setPolicy(event.value) }}
                                            // value={PolicyList.filter(option => option.value == Policy).length != 0 && PolicyList.filter(option => option.value == Policy)[0]}
                                            placeholder="--Select--"
                                        />
                                    </div><br />
                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row">
                                            <label className=" fs-5 fw-bold mb-2">Date of birth</label>
                                            <input type="date" name="Dob_date" className="form-control" id="Dob_date" placeholder="Sales Start Date" value={Dob_date}
                                                onChange={(e) => {
                                                    setDob_date(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 fv-row">
                                            <label className=" fs-5 fw-bold mb-2">Gender</label>
                                            <Select
                                                name="category"
                                                id="category"
                                                className="form-control form-control-solid"
                                                options={GenderList}
                                                onChange={(event) => { setGender(event.value) }}
                                                value={GenderList.filter(option => option.value == Gender).length != 0 && GenderList.filter(option => option.value == Gender)[0]}
                                                placeholder="--Select--"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row">
                                            <label className=" fs-5 fw-bold mb-2">Date of Joining</label>
                                            <input type="date" name="Joining_date" className="form-control" id="Joining_date" placeholder="Sales Start Date" value={Joining_date}
                                                onChange={(e) => {
                                                    setJoining_date(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 fv-row">
                                            <label className=" fs-5 fw-bold mb-2">Designation</label>
                                            <Select
                                                name="category"
                                                id="category"
                                                className="form-control form-control-solid"
                                                options={UserProfileList}
                                                onChange={(event) => { setDesignation(event.value) }}
                                                value={UserProfileList.filter(option => option.value == Designation).length != 0 && UserProfileList.filter(option => option.value == Designation)[0]}
                                                placeholder="--Select--"
                                            />
                                        </div>
                                    </div>
                                    <label class="form-check form-check-custom form-check-solid align-items-start">
                                        <input class="form-check-input me-3" type="checkbox" name="email-preferences[]" value={Skip_mail} onChange={(e) => {
                                            setSkip_mail(e.target.checked);
                                        }} />
                                        <span class="form-check-label d-flex flex-column align-items-start">
                                            <span class="fw-bolder fs-5 mb-0">Skip invitation email</span>
                                            {Skip_mail && <span class="text-muted fs-6">You can invite them later from Admin View - User Management.</span>}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write == 1 && <form >
                                {<button type="button" className="btn btn-success" onClick={(e) => { UpdateUser(e); }} ><span className="indicator-label" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>Update</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>}
                            </form>}
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="modal fade" id="deleteStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete User</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    <h3>Are you sure about delete the user?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write == 1 &&
                                <form >
                                    <input type="hidden" id="id" name="activeId" />
                                    <input type="hidden" id="st" name="st" />
                                    {<button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}><span className="indicator-label">Delete</span>
                                        <span className="indicator-progress">Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>}
                                </form>}
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    Current Status is : {(status == "Active" || status == "ACTIVE") ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                    <h3>Are you sure about changing status?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write == 1 && <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />

                                {(status == "Active" || status == "ACTIVE") ? <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}><span className="indicator-label">Deactivate</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                    :
                                    <button type="button" className="btn btn-success" onClick={(e) => { submitStatus(e); }} data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}><span className="indicator-label">Activate</span>
                                        <span className="indicator-progress">Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>}
                            </form>}
                        </div>
                    </div>
                </div>
            </div> */}

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />


        </>);

};

export default ViewProfiles;
