import React, { useState, useEffect } from 'react';
import RichTextEditor from 'react-rte';

const MyStatefulEditor = (props) => {
    // const [inputValue, setInputValue] = useState(RichTextEditor.createEmptyValue())
    console.log(props)
    const [flag, setFlag] = useState(false)
    const [inputValue, setInputValue] = useState(RichTextEditor.createValueFromString(props.value, 'html'))
    const handleChange = (value) => {
        setInputValue(value);
        props.onChange(value.toString('html'));
    };

    useEffect(() => {
        if (props.value !=="" && !flag){
            setFlag(true)
            setInputValue(RichTextEditor.createValueFromString(props.value, 'html'))
        }
    }, [props.value])

    // const [inputValue, setInputValue] =
    // React.useState(RichTextEditor.createValueFromString(value, 'markdown'));

    //   const handleChange = value => {
    //     setEditorValue(value);
    //     setValue(value.toString("markdown"));
    //   };
    return (
        <RichTextEditor
            value={inputValue}
            onChange={handleChange}
        />
    );
}


// class MyStatefulEditor extends Component {
//   static propTypes = {
//     onChange: PropTypes.func
//   };

//   state = {
//     value: RichTextEditor.createEmptyValue()
//   }

//   onChange = (value) => {
//     this.setState({value});
//     if (this.props.onChange) {
//       // Send the changes up to the parent component as an HTML string.
//       // This is here to demonstrate using `.toString()` but in a real app it
//       // would be better to avoid generating a string on each change.
//       this.props.onChange(
//         value.toString('html')
//       );
//     }
//   };

//   render () {
//     return (
//       <RichTextEditor
//         value={this.state.value}
//         onChange={this.onChange}
//       />
//     );
//   }
// }

export { MyStatefulEditor };