import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import { Link, Redirect } from 'react-router-dom'
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
// import {Form, Col,Button,InputGroup} from 'react-bootstrap';
import { Formik } from "formik";
import * as yup from "yup";
import moment from 'moment';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import uuid from 'react-uuid';

import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';


function Roles(props) {
    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);
    const [user, setUser] = useState({});
    // const [PurchaseDetails, setPurchaseDetails] = useState([]);
    const [state, setstate] = useState(2);

    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [Access, setAccess] = useState({ access: [] });
    const [newAccess, setNewAccess] = useState({ access: [] });
    // const [newActions, setnewActions] = useState([]);
    const [insErr, setInsErr] = useState([])

    const [role, setRole] = useState("");
    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);
    const [UserRoles, setUserRoles] = useState([]);

    const [Role_name, setRole_name] = useState("");
    const [Description, setDescription] = useState("");
    const [organization_id, setOrganization_id] = useState("");
    const [organization_list, setorganization_list] = useState([]);
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"));
    const [inputFields, setInputFields] = useState([{ fname: "", lname: "", email: "", phone: "" }]);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)


    var page_name = "add-role";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);

                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                window.location.href = "/login"
            });
        }
    }, [])



    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)

        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    // console.log(res.data)
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;

                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {

                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }
                            if (temp == 2) {
                                setredirect(1);
                            }
                        }

                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)

                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                       
                    else {
                        setRedirect_to_login(1);

                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);

                    });
            }
            else {
                setRedirect_to_login(1);

            }
        }

        organization_id != "" && validateJWT();

    }, [organization_id])
    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer) => {
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);

    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])
    useEffect(() => {
        async function get_default_role() {

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)
            if (body.length > 0) {
                setUserRoles(body)
                let arr = [];
                // console.log(JSON.parse(body[0].user_role_actions))
                // arr.push(JSON.parse(body[0].user_role_actions))
                // setnewActions(arr)
                setNewAccess(JSON.parse(body[0].user_role_access));
                setstate(1)
            }
            else {
                setstate(0);
            }
        }
        if (read || write) {
            (user.email && visibility) && get_default_role();
        }
    }, [user, visibility, read, write])

    function loadScripts() {

        var elem = document.getElementById("create_account");
        if (!elem) {
            var se = document.createElement('script');
            se.src = "/assets/js/custom/utilities/modals/create-account.js";
            se.id = "create_account";
            document.body.appendChild(se);
        }
    }

    const updateNewAccess = (val, section, sub1, sub2, part) => {
        // console.log(val, section, sub1, sub2, part)
        let temp = JSON.parse(JSON.stringify(newAccess))
        let temp2 = { access: [] }
        temp2.access = temp.access.map((obj) => {
            if (sub1 == undefined) {
                //root heading
                if (obj.key == section) {
                    return { ...obj, visibility: val ? 1 : 0 }
                }
                else {
                    return { ...obj }
                }
            }
            else {
                let temp3 = obj.inside_components.map((obj2) => {
                    if (part == undefined) {
                        //branch head
                        if (obj2.key == sub1) {
                            return { ...obj2, visibility: val ? 1 : 0 }
                        }
                        else {
                            return { ...obj2 }
                        }
                    }
                    else {
                        if (sub2 == undefined) {
                            //no nested branch
                            if (Object.keys(obj2)[0] == sub1) {
                                return { ...obj2, [part]: val ? 1 : 0 }
                            }
                            else {
                                return { ...obj2 }
                            }
                        }
                        else {
                            //nested branch
                            if (obj2.key == sub1) {
                                let temp4 = obj2.inside_components.map((obj3) => {
                                    if (Object.keys(obj3)[0] == sub2) {
                                        return { ...obj3, [part]: val ? 1 : 0 }
                                    }
                                    else {
                                        return { ...obj3 }
                                    }
                                })
                                return { ...obj2, inside_components: temp4 }
                            }
                            else {
                                return { ...obj2 }
                            }

                        }
                    }
                })

                return { ...obj, inside_components: temp3 }
            }
        })
        setNewAccess(temp2)
    }

    const register = () => {
        var sendButton = document.getElementById("kt_contact_submit_button");
        var object = {};
        if (Role_name == "") {
            swal("Warning!", "Select a Role name", "warning");
            return false;
        }
        object.organization_id = organization_id;
        object.service = "support";
        object.user_role = Role_name;
        object.role_description = Description;
        object.user_role_access = newAccess;
        //object.user_access = newAccess;
        object.page_name = page_name

        if (object.user_access == {} || object.user_access == [] || object.user_access == { access: [] }) {
            swal("Oops!", "Access permission invalid, try again", "error");
            return false;
        }

        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/save-role-details`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Role details added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
            });
    }
    // var files = [];

    const schema = yup.object({
        //   .required("*Email Required")
        //   .email("*Invalid Email") 
    });

    const ScrollToTop = () => {
        $('html').scrollTop(0);
    }

    const ScrollToBottom = () => {
        // window.scrollTo(0,document.body.scrollHeight);
        $('html,body').animate({ scrollTop: document.body.scrollHeight }, "smooth");
    }

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
 {
                redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
                    <Redirect to={{ pathname: "/login" }} />
                </> : null)
            }

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">New Roles

                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {state == 1 ?
                            <>
                                <div class="card">
                                    <div class="card-body">
                                        <button className="btn btn-secondary rounded m-3 p-5 float-right" onClick={ScrollToBottom} title="Scroll to Bottom">
                                            <i class="las la-angle-double-down" style={{ "font-size": "32px" }}></i>
                                        </button>
                                        <div class="stepper stepper-links d-flex flex-column pt-15 first" id="kt_create_account_stepper">
                                            <div class="stepper-nav py-5 mt-5">
                                                <div class="stepper-item current" data-kt-stepper-element="nav">
                                                    <h3 class="stepper-title">Enter Role Details</h3>
                                                </div>
                                                <div className="stepper-item" data-kt-stepper-element="nav">
                                                    <h3 className="stepper-title">Permissions</h3>
                                                </div>
                                                {/*<div class="stepper-item" data-kt-stepper-element="nav">
                                                    <h3 class="stepper-title">Actions</h3>
                                                </div>*/}

                                            </div>

                                            <Formik
                                                validationSchema={schema}
                                                onSubmit={register}
                                                initialValues={{

                                                }} >
                                                {({
                                                    handleChange,
                                                }) => (
                                                    <form class="mx-auto mw-600px w-100 pt-15 pb-10" id="kt_create_account_form"

                                                        noValidate
                                                    >
                                                        <div class="current" data-kt-stepper-element="content">
                                                            <div class="w-100">
                                                                {/* {console.log(inputFields)} */}
                                                                <div class="row mb-5">
                                                                    <div class="col-md-12 fv-row">
                                                                        <label class="required fs-5 fw-bold mb-2">Role Name</label>
                                                                        <input id="first_name" type="text" class="form-control form-control-solid" placeholder="" name="first-name" value={Role_name}
                                                                            onChange={(event) => { setRole_name(event.target.value); handleChange(event); }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-5">
                                                                    <div class="col-md-12 fv-row">
                                                                        <label class="fs-5 fw-bold mb-2">Description</label>
                                                                        <textarea id="email" type="email" class="form-control form-control-solid" placeholder="" name="email" value={Description}
                                                                            onChange={(event) => { setDescription(event.target.value); handleChange(event); }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                            </div>
                                                        </div>
                                                        <div data-kt-stepper-element="content">
                                                            <div className="w-100">
                                                                <div className="table-responsive">
                                                                    <table className="table table-row-dashed border-gray-100 align-middle gy-6">
                                                                        <tbody className="fs-6 fw-bold">
                                                                            <tr>
                                                                                <td className="min-w-240px fs-3 fw-bolder">Pages</td>
                                                                                <td className="min-w-240px fw-bolder">Visibility</td>
                                                                                <td className="w-125px">
                                                                                    <label className="form-check-label " for="kt_settings_notification_email">Read</label>
                                                                                </td>
                                                                                <td className="w-125px">
                                                                                    <label className="form-check-label " for="kt_settings_notification_phone">Write</label>
                                                                                </td>

                                                                            </tr>
                                                                            {newAccess["access"].length > 0 ? newAccess["access"].map((obj, index1) => {

                                                                                return (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="fs-4 fw-bolder">{obj.key.toUpperCase()}</td>

                                                                                            <td>
                                                                                                <div className="form-check form-check-solid">
                                                                                                    {/* {console.log(obj.visibility)} */}
                                                                                                    <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, undefined, undefined, undefined)} id={`visibility_${obj.key.replace(/-/g, "_")}`} checked={obj.visibility == 1} />
                                                                                                    <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                </div>
                                                                                            </td>

                                                                                        </tr>
                                                                                        {obj.inside_components.length > 0 ? obj.inside_components.map((obj2, index2) => {
                                                                                            return (
                                                                                                obj2.hasOwnProperty("key") && obj2.inside_components.length > 0 ?
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td className="ps-4 fs-4 fw-bold">{obj2.key.toUpperCase()}</td>

                                                                                                            <td>
                                                                                                                <div className="form-check form-check-solid">
                                                                                                                    {/* {console.log(obj.visibility)} */}
                                                                                                                    <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, undefined, undefined)} id={`visibility_${obj.key.replace(/-/g, "_")}`} checked={obj2.visibility == 1} />
                                                                                                                    <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                </div>
                                                                                                            </td>

                                                                                                        </tr>
                                                                                                        {
                                                                                                            obj2.inside_components.map((obj3) => {
                                                                                                                // console.log(Object.keys(obj3)[0],":",obj3[Object.keys(obj3)[0]])
                                                                                                                return (
                                                                                                                    <tr>
                                                                                                                        <td className="ps-8">{Object.keys(obj3)[0]}</td>
                                                                                                                        <td>
                                                                                                                            <div className="form-check form-check-solid">
                                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], Object.keys(obj3)[0])} id={`visibility_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[0]] == 1} />
                                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <div className="form-check form-check-solid">
                                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], "read")} id={`read_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[1]] == 1} />
                                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <div className="form-check form-check-solid">
                                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], "write")} id={`write_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[2]] == 1} />
                                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    <tr>
                                                                                                        <td className="ps-4">{Object.keys(obj2)[0]}</td>
                                                                                                        <td>
                                                                                                            <div className="form-check form-check-solid">
                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, Object.keys(obj2)[0])} id={`visibility_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[0]] == 1} />
                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="form-check form-check-solid">
                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, "read")} id={`read_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[1]] == 1} />
                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="form-check form-check-solid">
                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, "write")} id={`write_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[2]] == 1} />
                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                            )
                                                                                        }) : null}
                                                                                    </>
                                                                                );
                                                                            })
                                                                                :
                                                                                null
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div class="d-flex flex-stack pt-15">
                                                            <div class="mr-2">
                                                                <button type="button" class="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
                                                                    <span class="svg-icon svg-icon-4 me-1">
                                                                        Previous
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div>
                                                                {write == 1 && <button
                                                                    type="submit"
                                                                    id="kt_contact_submit_button"
                                                                    class="btn btn-lg btn-primary me-3"
                                                                    data-kt-stepper-action="submit"
                                                                    data-kt-indicator={mainSubmitButtonState ? "on" : "off"}
                                                                    disabled={mainSubmitButtonState}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        // handleSubmit();
                                                                        register();
                                                                    }}

                                                                >
                                                                    <span className="indicator-label">Submit</span>
                                                                    <span className="indicator-progress">Please wait...
                                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                                </button>}
                                                                <button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="next">Next
                                                                    <span class="svg-icon svg-icon-4 ms-1 me-0">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                                                <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                        <button className="btn btn-secondary rounded m-3 p-5 float-right" onClick={ScrollToTop} title="Scroll to Top">
                                            <i class="las la-angle-double-up" style={{ "font-size": "32px" }}></i>
                                        </button>
                                    </div>
                                </div>
                                {<div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>}
                            </>
                            :
                            <>
                                {state == 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <>
                                        <div className="flex-lg-row-fluid me-lg-15">
                                            <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                                <div className="card-header">
                                                    <div className="card-title">
                                                        <h2 className="fw-bolder">Add Roles</h2>
                                                    </div>
                                                </div>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                    <div className="d-flex align-items-center p-3 mb-2">
                                                    </div>
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">Something Went wrong.</h4>
                                                                <div className="fs-6 text-gray-600">
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </>
                                }
                            </>
                        }
                    </div>
                </div>

            </div>



            
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />


        </>
    );
}
export default Roles;