import { useState, useEffect } from "react";
import axios from 'axios';
import "./PushNotification.css";


const isPushNotificationSupported = () => {
  return "serviceWorker" in navigator && "PushManager" in window;
}
const pushNotificationSupported = isPushNotificationSupported();

const UsePushNotifications = (props) => {
  const { appName, userName, orgId, origin, token } = props;

  const [userConsent, setSuserConsent] = useState(Notification.permission);
  const [userSubscription, setUserSubscription] = useState(null);
  const [pushServerSubscriptionId, setPushServerSubscriptionId] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ribbonState, setRibbonState] = useState(true);

  const registerServiceWorker = () => {
    return navigator.serviceWorker.register("/serviceWorker.js");
  }

  const createNotificationSubscription = async () => {
    const serviceWorker = await navigator.serviceWorker.ready;
    // console.log(process.env.REACT_PUSH_SERVER_PUBLIC_KEY)
    return await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: process.env.REACT_APP_PUSH_SERVER_PUBLIC_KEY
    });
  }

  const getUserSubscription = () => {
    return navigator.serviceWorker.ready
      .then((serviceWorker) => {
        return serviceWorker.pushManager.getSubscription();
      })
      .then((pushSubscription) => {
        return pushSubscription;
      });
  }

  useEffect(() => {
    if (pushNotificationSupported) {
      setLoading(true);
      setError(false);
      registerServiceWorker().then(() => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const getExixtingSubscription = async () => {
      const existingSubscription = await getUserSubscription();
      setUserSubscription(existingSubscription);
      setLoading(false);
    };
    getExixtingSubscription();
  }, []);


  const AskUserPermission = () => {
    setLoading(true);
    setError(false);
    Notification.requestPermission().then(consent => {
      setSuserConsent(consent);
      if (consent === "denied") {
        setError({
          name: "Notification Denied",
          message: "Enable permission from Site settings",
          code: 0
        });
      }
      setLoading(false);
    });
  };


  const SusbribeToPushNotification = () => {
    setLoading(true);
    setError(false);
    createNotificationSubscription()
      .then(function (subscrition) {
        setUserSubscription(subscrition);
        setLoading(false);
      })
      .catch(err => {
        console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
        setError(err);
        setLoading(false);
      });
  };

  const SendSubscriptionToPushServer = () => {
    // console.log("subscription to server")
    setLoading(true);
    setError(false);
    axios.post(`${process.env.REACT_APP_SERVER_URL}/subscription`, {
      subscriptionData: userSubscription,
      app_name: appName,
      organization_id: orgId,
      user_name: userName,
      origin: origin
    }, {headers: { "content-type": "application/json;charset=UTF-8", "Accept": "*/*",
    "token": token}})
      .then((response) => {
        setPushServerSubscriptionId(response.data.id);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err)
        setError(err);
      });
  };

  const SendNotification = async () => {
    setLoading(true);
    setError(false);
    await axios.get(`${process.env.REACT_APP_SERVER_URL}/subscription/${pushServerSubscriptionId}`, {headers: { "content-type": "application/json;charset=UTF-8", "Accept": "*/*",
    "token": token}})
      .catch(err => {
        setLoading(false);
        setError(err);
      });
    setLoading(false);

  };

  // return {
  //   AskUserPermission,
  //   SusbribeToPushNotification,
  //   SendSubscriptionToPushServer,
  //   SendNotification,
  //   userConsent,
  //   pushNotificationSupported,
  //   userSubscription,
  //   pushServerSubscriptionId,
  //   error,
  //   loading
  // };

  const Loading = ({ loading }) => (loading ? <div className="app-loader">Please wait, we are loading something...</div> : null);
  const Error = ({ error }) =>
    error ? (
      <section className="app-error">
        <code>
          <h2>{error.name}</h2>
          <p>MESSAGE : {error.message}</p>
          <p>CODE : {error.code}</p>
        </code>
      </section>
    ) : null;

  const isConsentGranted = userConsent === "granted";
  const isConsentDenied = userConsent === "denied";
  useEffect(() => {
    AskUserPermission()
  }, [pushNotificationSupported, userConsent])

  useEffect(() => {
    if (isConsentGranted) {
      SusbribeToPushNotification()
    }
  }, [userConsent])

  useEffect(() => {
    if (isConsentGranted && userSubscription) {
      SendSubscriptionToPushServer()
    }
  }, [userConsent, userSubscription])

  // useEffect(() => {
  //   if (isConsentGranted && pushServerSubscriptionId) {
  //     SendNotification()
  //   }
  // }, [userConsent, userSubscription, pushServerSubscriptionId])
  // console.log([pushNotificationSupported, userConsent, userSubscription, pushServerSubscriptionId])

  return (
    <main>
      {/* <h1>eholl</h1> */}

      {!pushNotificationSupported  && ribbonState?
        <div className="ribbon" >Your browser does not support Push notification
          <button type="button" className="btn-close" onClick={()=>setRibbonState(false)}/>

        </div>
        : null
      }
      {pushNotificationSupported && isConsentDenied && ribbonState?
        <div className="tip d-flex">
          {/* <div></div> */}
          <span className="flex-grow-1">"Allow" notifications for this site</span>
          <button type="button" className="btn-close" onClick={()=>setRibbonState(false)}/>
          
        </div>
        : null
      }

      {/* <Loading loading={loading} />
      <Error error={error} /> */}

    </main>
  )
}

export default UsePushNotifications
