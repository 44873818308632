import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route, Router } from "react-router-dom";

import ActivityLogAlert from './includes/activity-log-alert.js';

import Dashboard from './includes/dashboard.js';
import Signin from './includes/sign-in.js';
import Signup from './includes/sign-up.js';

import AccountOverview from './includes/account-overview.js';
import AccountSetting from './includes/account-setting.js';
import AccountBilling from './includes/account-billing.js';
import AccountSecurity from './includes/account-security.js';

import ActicityLogs from "./includes/acticityLogs.js";

import Support from "./includes/support.js";
import QueryList from "./includes/support-query-list.js";

import AddDepartment from "./includes/add-department";
import ListDepartment from "./includes/list-department";

import AddTicket from "./includes/add-ticket";
import ListTicket from "./includes/list-ticket";
import TicketConversations from "./includes/ticket-conversations";
import AddAgents from "./includes/add-agents";
import ListAgents from "./includes/list-agents";
import UpdateAgents from "./includes/update-agents";

import AddTeam from "./includes/add-team";
import ListTeam from "./includes/list-team";

import ViewSupportDetails from "./includes/view-support-details.js";

import AsideNavbar from "./includes/aside-navbar.js";
import Header from "./includes/header.js";
import Footer from "./includes/footer.js";

import ForgotPassword from "./includes/forgot-password.js";
import FirstStepForgotPassword from "./includes/first-step-forgot-password.js";

import Customer from "./includes/add-customer.js";
import ShowCustomer from "./includes/show-customer.js";

import ViewCustomerDetails from "./includes/view-customer-details.js"
import NotFound from "./includes/NotFound.js";
import UnAuthorized from "./includes/unAuthorized.js";

import ProductAdditionalInformation from "./includes/product-additional-information";

import ShowProduct from "./includes/show-product.js";

import ProductImages from "./includes/product-images";

import ProductVideo from "./includes/product-video.js";
import QuickSetUp from './includes/quick-set-up.js';
import ShowOrganizations from "./includes/show-organizations.js";
import OrganizationProfile from './includes/organization-profile.js';
import AddArticle from './includes/add-article';
import SpamTickets from './includes/spam-tickets';

import AddProfile from './includes/add-profile.js';
import ShowProfiles from './includes/show-profiles.js';
import UpdateProfile from './includes/update-profile.js';
import ViewProfile from './includes/view-profiles.js';

import Roles from './includes/add-roles.js';
import ShowRoles from './includes/show-roles.js';
import UpdateRoles from './includes/update-roles.js';
import ViewRoles from './includes/view-roles.js';

import CreateAccount from './includes/create-account.js';
import VerificationAccount from './includes/verification-account.js';

export default function BasePage() {

	return (
		<Switch>
			<Route exact path={"/login"} component={Signin} />
			<Route exact path={"/register"} component={Signup} />
			<Route exact path={"/quick-set-up"} component={QuickSetUp} />
			<Route exact from="/create-account/:id" render={(props) => <CreateAccount {...props} />} />
			<Route exact from="/verification-account/:id" render={(props) => <VerificationAccount {...props} />} />

			<Route exact path={"/forgot-password/:id"} component={ForgotPassword} />
			<Route exact path={"/forgot-password-email"} component={FirstStepForgotPassword} />
			<Route exact from="/not-found" component={NotFound} />
			<Route exact path={"/unauthorized"} component={UnAuthorized} />
			<Route component={DefaultContainer} />
		</Switch>
	);
}

const DefaultContainer = () => (
	<>

		<div className="d-flex flex-column flex-root app-root" id="kt_app_root">
			<div className="app-page flex-column flex-column-fluid" id="kt_app_page">
				<ActivityLogAlert />

				<Header />


				<div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">

					<AsideNavbar />
					<div className="app-main flex-column flex-row-fluid" id="kt_app_main">
						<Switch>
							<Route exact from="/" component={Dashboard} />
							<Route exact path={"/dashboard"} component={Dashboard} />
							<Route exact path={"/account-overview"} component={AccountOverview} />
							{/* <Route exact path={"/account-setting"} component={AccountSetting} />
							<Route exact path={"/account-billing"} component={AccountBilling} />
							<Route exact path={"/account-security"} component={AccountSecurity} /> */}

							{/* <Route exact path={"/activity"} component={ActicityLogs} /> */}

							{/* <Route exact path={"/support"} component={Support} />
							<Route exact path={"/support-query-list"} component={QueryList} />
							<Route exact path={"/view-support-details/:id"} render={(props) => <ViewSupportDetails {...props} />} /> */}

							<Route exact path={"/add-department"} component={AddDepartment} />
							<Route exact path={"/list-department"} component={ListDepartment} />

							<Route exact path={"/add-agents"} component={AddAgents} />
							<Route exact path={"/list-agents"} component={ListAgents} />
							<Route exact path={"/update-agents/:id"} render={(props) => <UpdateAgents {...props} />} />

							<Route exact path={"/add-team"} component={AddTeam} />
							<Route exact path={"/list-team"} component={ListTeam} />

							<Route exact from="/add-customer" component={Customer} />

							{/* <Route exact from="/customer-list" component={ShowCustomer} /> */}
							{/* <Route exact path={"/view-customer-details/:id"} render={(props) => <ViewCustomerDetails {...props} />} /> */}
							<Route exact path={"/ticket-conversations/:id"} render={(props) => <TicketConversations {...props} />} />
							<Route exact from="/add-ticket" component={AddTicket} />
							<Route exact from="/ticket-list" component={ListTicket} />
							<Route exact from="/product-list" component={ShowProduct} />
							<Route exact path={"/product-images/:pid"} render={(props) => <ProductImages {...props} />} />
							<Route exact path={"/product-video/:pid"} render={(props) => <ProductVideo {...props} />} />
							<Route exact path={"/product-additional-information/:id"} render={(props) => <ProductAdditionalInformation {...props} />} />
							<Route exact from="/show-organizations" component={ShowOrganizations} />
							<Route exact from="/organization-profile" component={OrganizationProfile} />
							<Route exact from="/add-article" component={AddArticle} />
							<Route exact from="/spam-tickets" component={SpamTickets} />

							<Route exact from="/add-profile" component={AddProfile} />
							<Route exact from="/profile-list" component={ShowProfiles} />
							<Route exact from="/update-profile/:id" render={(props) => <UpdateProfile {...props} />} />
							<Route exact from="/view-profile/:id" render={(props) => <ViewProfile {...props} />} />

							<Route exact from="/add-role" component={Roles} />
							<Route exact from="/role-list" component={ShowRoles} />
							<Route exact from="/update-role/:id" render={(props) => <UpdateRoles {...props} />} />
							<Route exact from="/view-role/:id" render={(props) => <ViewRoles {...props} />} />

							<Route from="*" ><Redirect to="/not-found" /> </Route>
						</Switch>

						<Footer />
					</div>
				</div>
			</div>
		</div>
	</>
)