import React, { useState, useEffect } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import ReactTagInput from "@pathofdev/react-tag-input";
import MUIDataTable from "mui-datatables";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import SetColor from './SetColor.js';
import "@pathofdev/react-tag-input/build/index.css";
import { MuiThemeProvider, createTheme } from '@material-ui/core';

export const ShowProduct = () => {
    const [accessToken, setaccessToken] = useState('');
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [unique_id, setUnique_id] = useState("");
    const [PurchaseDetails, setPurchaseDetails] = useState([]);
    //const [loading, setloading] = useState(false);
    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);
    const [groupname, setGroupname] = useState("");
    const [account_head, setAccount_head] = useState("");
    const [accountdesc, setAccountdesc] = useState("");
    const [product_name, setProduct_name] = useState("");
    const [vendor_name, setVendor_name] = useState("");
    const [manufacturer_name, setManufacturer_name] = useState("");
    const [start_date, setstart_date] = useState("");
    const [end_date, setend_date] = useState("");
    const [shortdesc, setShortdesc] = useState("");
    const [longdesc, setLongdesc] = useState("");
    const [product_active, setproduct_active] = useState("Yes");
    // let [group_type, setGroup_type] = useState("");
    const [status, setstatus] = useState("");
    const [tags, setTags] = React.useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [category, setCategory] = useState([]);
    const [outofstock, setoutofstock] = useState('');
    const [product_name1, setproduct_name1] = useState("");

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    //const [organization_id, setOrganization_id] = useState("");
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [loading, setLoading] = React.useState(true);
    const [items, setItems] = React.useState([]);


    const [loading2, setLoading2] = React.useState(true);
    const [items2, setItems2] = React.useState([]);

    const [loading3, setLoading3] = React.useState(true);
    const [items3, setItems3] = React.useState([]);

    const [loading4, setLoading4] = React.useState(true);
    const [items4, setItems4] = React.useState([]);

    const [globalsubcategory, setGlobalSubcategory] = useState([]);
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    var page_name = "product-list";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };
    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])
    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Group',
    };

    const showproduct_outofstock = (event) => {
        if (event.target.checked) {
            setoutofstock("Yes");
        }
        else {
            setoutofstock("No");
        }
    }


    const columns = [
        {
            name: "product_name",
            label: "Product Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "vendor_name",
            label: "Vendor name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "subcategory_name",
            label: "Subcategory name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "category",
            label: "Category",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "product_active",
            label: "Product Active",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();
    }, [])

    useEffect(() => {
        if (timer > 0) {
            const timer_decrease = setInterval(() => {
                setTimer(timer - 1);
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        window.$('#kt_modal_timer').modal('show');
                    }
                }
            }, 1000);

            return () => clearInterval(timer_decrease);
        }
    }, [timer]);



    useEffect(() => {

        async function getvendor() {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/vendor-datatable`, {
                method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
            }
            );
            const body = await response.json();

            //console.log(body);
            setItems3(body);
            setLoading3(false);
        }
        if (read || write) {
            (user.email && visibility) && getvendor();
        }

        async function getmanufacturer() {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/manufacturer-datatable`, {
                method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
            }
            );
            const body = await response.json();

            //console.log(body);
            setItems4(body);
            setLoading4(false);
        }
        if (read || write) {
            (user.email && visibility) && getmanufacturer();
        }


        async function getCategories() {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/category-datatable`, {
                method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
            }
            );
            const body = await response.json();
            //console.log(body);
            setItems(body);
            setLoading(false);
        }

        if (read || write) {
            (user.email && visibility) && getCategories();
        }

        async function getsubCategories() {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/subcategory-datatable`, {
                method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
            }
            );
            const body = await response.json();
            setGlobalSubcategory(body);
        }

        if (read || write) {
            (user.email && visibility) && getsubCategories();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function getpurchasedetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/product-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            setEditModal(body);

            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }

            setLoading(true);
        }
        if (read || write) {
            user.email && visibility && getpurchasedetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function geteditdetails() {

            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {

                    var temp_obj = {};
                    temp_obj.product_name = obj.product_name;
                    temp_obj.category = obj.category_name;
                    temp_obj.vendor_name = obj.vendor_name;
                    temp_obj.subcategory_name = obj.subcategory_name;
                    temp_obj.product_active = obj.product_active;
                    temp_obj.status = obj.add_status;
                    var id = obj.pid;
                    // console.log(id)
                    temp_obj.actions =
                        <div class="dropdown">
                            {
                                write ?
                                    <>
                                        <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fas fa-bars px-0"></i>
                                        </button>

                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a href="#" onClick={(e) => { updateAccount(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                            <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Change status</a></li>
                                            <li><Link to={`/product-price-information/${id}`} class="dropdown-item" >Add/Edit price</Link></li>
                                            <li><Link to={`/product-special-discount/${id}`} class="dropdown-item" >Add Special Discount</Link></li>
                                            <li><Link to={`/product-stock-information/${id}`} class="dropdown-item">Add/Edit stock</Link></li>
                                            <li><Link to={`/product-images/${id}`} class="dropdown-item">Add/Edit product image</Link></li>
                                            <li><Link to={`/product-video/${id}`} data-bs-target="#EditStatus" class="dropdown-item">Add/Edit product video</Link></li>
                                            <li><Link to={`/product-additional-information/${id}`} class="dropdown-item">Add/Edit product additional information</Link></li>
                                            <li><a onClick={(e) => { outofstock1(id) }} data-bs-toggle="modal" data-bs-target="#Editoutofstock" class="dropdown-item" href="#">Show this product out of stock</a></li>
                                            <li><Link to={`/update-product-serial/${id}`} class="dropdown-item">Add/Edit product serial</Link></li>
                                        </ul>
                                    </>
                                    :
                                    <>
                                        <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Edit
                                        </button>

                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item">Edit details</a></li>
                                            <li><a class="dropdown-item" href="#">Change status</a></li>
                                            <li><Link to={`/product-price-information/${id}`} class="dropdown-item" >Add/Edit price</Link></li>
                                            <li><Link to={`/product-special-discount/${id}`} class="dropdown-item" >Add Special Discount</Link></li>
                                            <li><Link to={`/product-stock-information/${id}`} class="dropdown-item">Add/Edit stock</Link></li>
                                            <li><Link to={`/product-images/${id}`} class="dropdown-item">Add/Edit product image</Link></li>
                                            <li><Link to={`/product-video/${id}`} data-bs-target="#EditStatus" class="dropdown-item">Add/Edit product video</Link></li>
                                            <li><Link to={`/product-additional-information/${id}`} class="dropdown-item">Add/Edit product additional information</Link></li>
                                            <li><a class="dropdown-item" >Show this product out of stock</a></li>
                                            <li><Link to={`/update-product-serial/${id}`} class="dropdown-item">Add/Edit product serial</Link></li>
                                        </ul>
                                    </>
                            }

                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();

    }, [editmodal])



    const updateAccount = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].pid) {
                const modalid = document.getElementById("EditModal");
                //console.log(editmodal[i].sub_category);
                if (editmodal[i].product_active == "Yes") {
                    document.getElementById("product_active").checked = true;
                }
                else {
                    document.getElementById("product_active").checked = false;
                }
                document.getElementById("id").value = id;
                setProduct_name(editmodal[i].product_name);
                setVendor_name(editmodal[i].vendor);
                setManufacturer_name(editmodal[i].manufacturer);
                setLongdesc(editmodal[i].long_description);
                setShortdesc(editmodal[i].short_description);
                setSubcategory(editmodal[i].sub_category);
                setCategory(editmodal[i].category);
                setstart_date(moment(editmodal[i].start_date).format('YYYY-MM-DD'));
                setend_date(moment(editmodal[i].end_date).format('YYYY-MM-DD'));
                var str = []
                str = (editmodal[i].tags).split(',');
                //console.log(str);
                setTags(str)
                getsubcategory1(editmodal[i].category);
            }
        }
    }
    async function outofstock1(id) {
        document.getElementById("outofstock_id").value = id;
        const result = editmodal.filter(obj => obj.pid == id);
        setproduct_name1(result[0].product_name)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/product-out-of-stock`, {
            method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "id": id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        }
        );
        const body = await response.json();
        if (body.length > 0) {
            setoutofstock("Yes");
            document.getElementById("product_outofstock").checked = true;
        }
        else {
            setoutofstock("No")
            document.getElementById("product_outofstock").checked = false;
        }

        //setstock_status(body[0].add_status);

    }
    const updateStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].pid) {
                document.getElementById("st").value = editmodal[i].add_status;
                document.getElementById("id").value = id;
                setstatus(editmodal[i].add_status);
            }
        }
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var id = document.getElementById("id").value;
        var status_value = document.getElementById("st").value;
        var values = { "id": id, "status": status_value, "page_name": page_name };

        //console.log(values);

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-product`, values, headers).then((res) => {
            //console.log(res)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Status changed successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const showinoutofstock = () => {

        var id = document.getElementById("outofstock_id").value;

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        var values = { "id": id, "outofstock": outofstock };
        values.page_name = page_name;

        axios.post(`${process.env.REACT_APP_SERVER_URL}/set-product-out-of-stock`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Product is out of stock!", "success").then((value) => {
                    window.location.reload();
                });
            }

            else if (res.data && res.data.operation == "successn") {
                swal("Great!", "Product is removed from out of stock!", "success").then((value) => {
                    window.location.reload();
                });
            }

            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }
    const updateProductdetails = (e) => {
        e.preventDefault();
        var id = document.getElementById("id").value;
        var product_name = document.getElementById("product_name").value;
        var vendor = document.getElementById("vendor_name").value;
        var manufacturer = document.getElementById("manufacturer_name").value;
        var category = document.getElementById("category").value;
        var sub_category = document.getElementById("sub_category").value;
        var short_desc = document.getElementById("short_description").value;
        var long_desc = document.getElementById("long_description").value;
        var start_date = document.getElementById("start_date").value;
        var end_date = document.getElementById("end_date").value;
        var tags1 = (tags.toString());

        var elem = document.getElementById("product_active");

        var product_active = 'off';
        if (elem.checked) {
            product_active = 'on';
        }

        if (product_name == "") {
            swal("Warning!", "Provide a product name", "warning");
            return false;
        }

        if (vendor == "") {
            swal("Warning!", "Select a vendor name", "warning");
            return false;
        }
        if (manufacturer == "") {
            swal("Warning!", "Select a manufacturer", "warning");
            return false;
        }
        if (category == "") {
            swal("Warning!", "Select a category", "warning");
            return false;
        }
        if (sub_category == "") {
            swal("Warning!", "Select a subcategory", "warning");
            return false;
        }
        if (start_date == "") {
            swal("Warning!", "Select a start date", "warning");
            return false;
        }
        if (end_date == "") {
            swal("Warning!", "Select a end date", "warning");
            return false;
        }

        var values = { "id": id, "product_name": product_name, "vendor": vendor, "manufacturer": manufacturer, "short_desc": short_desc, "end_date": end_date, "tags": tags1, "start_date": start_date, "long_desc": long_desc, "category": category, "sub_category": sub_category, "product_active": product_active, "page_name": page_name };

        //.log(values)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-product`, values, headers).then((res) => {
            //console.log('response ',res.data)
            if (res.data.operation === "duplicate_name") {
                swal("Oops!", "product with this name already exist!!", "error");
            }
            else if (res.data && res.data.operation == "success") {
                swal("Great!", "Product details updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });

    }
    async function getsubcategory1(values) {
        const result = globalsubcategory.filter(obj => obj.category == values);
        setItems2(result);
        setLoading3(false);
    }
    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Product List
                            {/* <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span> */}
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">

                            <div className="card-body pt-6">
                                {
                                    data.length > 0 ?
                                        <>
                                            <MuiThemeProvider theme={createTheme({ palette: { type: userTheme } })}>
                                                <MUIDataTable
                                                    title={"Account Details"}
                                                    data={data}
                                                    columns={columns}
                                                    options={options}
                                                />
                                            </MuiThemeProvider>

                                        </>
                                        :
                                        <>
                                            {
                                                state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    :
                                                    <>
                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5">Show Product</div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                        <div className="fs-6 text-gray-600"> </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <div className="modal fade" id="Editoutofstock" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Show {product_name1} out of stock</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    <label className="col-12 col-form-label">Do you want to show this product out of stock?</label>
                                    <span className="kt-switch kt-switch--success">
                                        <label>
                                            <div className="form-check form-check-solid form-switch fv-row">
                                                <input type="checkbox" name="product_outofstock" className="form-check-input w-45px h-30px" id="product_outofstock" value="on"
                                                    onChange={(e) => {
                                                        showproduct_outofstock(e)
                                                    }}
                                                />
                                            </div>
                                            <span></span>
                                        </label>
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <input type="hidden" id="outofstock_id" name="outofstock_id" />

                                <button type="button" className="btn btn-success" onClick={showinoutofstock} >Submit</button>
                            </form>
                        </div>

                    </div>

                </div>

            </div>
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    Current Status is : {status == "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                    <h3>Are you sure about changing status?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />

                                {(status == "Active") ? <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} >Deactivate</button> : <button type="button" className="btn btn-success" onClick={(e) => { submitStatus(e); }} >Activate</button>}
                            </form>
                        </div>

                    </div>

                </div>

            </div>
            <div class="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-650px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>Update Product</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                    <div class="row mb-5">
                                        <div class="col-md-12 fv-row">
                                            <label class="required fs-5 fw-bold mb-2">Product Name</label>
                                            <input id="product_name" type="text" class="form-control form-control-solid" placeholder="" name="product-name" value={product_name} onChange={(e) => { setProduct_name(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div class="row mb-5">
                                        <div class="col-md-12 fv-row">
                                            <label class="required fs-5 fw-bold mb-2">Vendor Name</label>
                                            <select className="form-control" id="vendor_name" name="vendor_name" value={vendor_name} onChange={(e) => {
                                                setVendor_name(e.target.value)
                                            }} >
                                                <option value="">Select</option>
                                                {items3.map((obj) => (
                                                    <option key={obj.vid} value={obj.vid}>
                                                        {obj.vendor_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mb-5">
                                        <div class="col-md-12 fv-row">
                                            <label class="required fs-5 fw-bold mb-2">Manufacturer Name</label>
                                            <select className="form-control" id="manufacturer_name" name="manufacturer" value={manufacturer_name} onChange={(e) => {
                                                setManufacturer_name(e.target.value)
                                            }} >
                                                <option value="">Select</option>
                                                {items4.map((obj) => (
                                                    <option key={obj.mid} value={obj.mid}>
                                                        {obj.manufacturer_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label class="required fs-5 fw-bold mb-2">Start Date</label>
                                            <input type="date" name="start_date" className="form-control form-control-solid" id="start_date"
                                                value={start_date}
                                                onChange={(e) => { setstart_date(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                    <div class="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label class="required fs-5 fw-bold mb-2">End Date</label>
                                            <input type="date" name="end_date" className="form-control form-control-solid" id="end_date"
                                                value={end_date}
                                                onChange={(e) => { setend_date(e.target.value) }}
                                            />
                                        </div>
                                    </div>


                                    <div className="row mb-5">
                                        <div className="d-flex flex-column mb-2 fv-row">
                                            <label className="fs-6 fw-bold mb-2">Long Description</label>
                                            <textarea id="long_description" className="form-control form-control-solid" rows="3" name="long-discription" placeholder="" autocomplete="off" value={longdesc} onChange={(e) => { setLongdesc(e.target.value) }}></textarea></div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="d-flex flex-column mb-2 fv-row">
                                            <label className="fs-6 fw-bold mb-2">Sort Description</label>
                                            <textarea id="short_description" className="form-control form-control-solid" rows="3" name="short-discription" placeholder="" autocomplete="off" value={shortdesc} onChange={(e) => { setShortdesc(e.target.value) }}></textarea></div>
                                    </div>

                                    <div class="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Tags</label>
                                            <ReactTagInput
                                                tags={tags}
                                                onChange={(newTags) => setTags(newTags)}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Category</label>
                                            <select className="form-control" id="category" name="category" value={category} onChange={(e) => {
                                                setCategory(e.target.value)
                                                getsubcategory1(e.target.value);
                                            }} >
                                                <option value="">Select</option>
                                                {items.map((obj) => (
                                                    <option key={obj.id} value={obj.id}>
                                                        {obj.category_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Subcategory</label>
                                            <select className="form-control" id="sub_category" name="sub_category" value={subcategory} onChange={(e) => { setSubcategory(e.target.value) }} >
                                                <option value="">Select</option>
                                                {items2.map((obj) => (
                                                    <option key={obj.id} value={obj.id}>
                                                        {obj.subcategory_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>



                                    <div className="row mb-0">

                                        <label className="col-lg-4 col-form-label fw-bold fs-5">Product Active</label>


                                        <div className="col-lg-8 d-flex align-items-center">
                                            <div className="form-check form-check-solid form-switch fv-row">
                                                <input className="form-check-input w-45px h-30px" type="checkbox" id="product_active" name="product_active" value="on" />
                                                <label className="form-check-label" for="product_active"></label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer flex-center">
                                <button onClick={(e) => { updateProductdetails(e) }} type="submit" id="kt_modal_new_address_submit" class="btn btn-primary rounded">
                                    <span class="indicator-label">Submit</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>


            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
        </>);

};

export default ShowProduct;
