import React, { useState, useEffect, useRef } from 'react';


import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect, useHistory } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import ReactTagInput from "@pathofdev/react-tag-input";
import MUIDataTable from "mui-datatables";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import SetColor from './SetColor.js';
import "@pathofdev/react-tag-input/build/index.css";
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

export const ListTicket = () => {
    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);
    const [user, setUser] = useState({});
    // const [PurchaseDetails, setPurchaseDetails] = useState([]);
    const [state, setstate] = useState(2);

    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [user_id, setUser_id] = useState("");


    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);
    const [allTickets, setAllTickets] = useState([]);
    const [status, setstatus] = useState("");

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    const [object, setobject] = useState({});

    const [loading, setLoading] = useState(true);

    const [agentsGlobal, setAgentsGlobal] = useState([]);
    const [usersGlobal, setUsersGlobal] = useState([]);
    const history = useHistory()
    const [organization_list, setorganization_list] = useState([]);
    const [organization_id, setOrganization_id] = useState("");
    const [quickView, setQuickView] = useState(false);
    const [peekTicket, setPeekTicket] = useState({});
    const [allConv, setAllConv] = useState([]);
    const [ViewDetails, setViewDetails] = useState([]);
    const [ViewInternalDetails, setViewInternalDetails] = useState([]);
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    var page_name = "ticket-list";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };
    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }
    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])



    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);

                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                window.location.href = "/login"
            });
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)

        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        // setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)

                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);

                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);

                    });
            }
            else {
                setRedirect_to_login(1);

            }
        }
        organization_id != "" && validateJWT();
    }, [organization_id])


    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer) => {
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);


    useEffect(() => {
        async function getuserdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.length > 0) {
                let agent_roles = body.filter(obj => obj.user_profile.includes("Agent"))
                setAgentsGlobal(agent_roles)
                setUsersGlobal(body)
            }
            else {
                setAgentsGlobal([])
                setUsersGlobal([])
            }

        }
        if (read || write) {
            user.email && visibility && getuserdetails();
        }
        async function gettickets() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/ticket-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)
            if (body.length > 0) {
                // let agent_roles = body.filter(obj => obj.user_role.includes("Agent"))
                setEditModal(body)
                setAllTickets(body)
                setstate(1);
            }
            else {
                setEditModal([])
                setstate(0);
            }

            setLoading(true);
        }
        if (read || write) {
            user.email && visibility && gettickets();
        }

    }, [user, visibility, read, write])

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search ticket',
        onRowClick: (rowData) => history.push(`/ticket-conversations/${rowData[2]}`),
        // onCellClick: (cellMeta) => console.log(cellMeta)
        // onCellClick:(colData)=> console.log(colData)
    };

    const columns = [
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "ticket_no",
            label: "Ticket No",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "ticket_id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: "customer_name",
            label: "Customer",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "subject",
            label: "Subject",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "due_date_time",
            label: "Due By",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "priority",
            label: "priority",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        // {
        //     name: "generate_time",
        //     label: "Generate Time",
        //     options: {
        //         filter: true,
        //         sort: true
        //     }
        // },
        {
            name: "quick_peek",
            label: "options",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    useEffect(() => {
        async function geteditdetails() {
            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {

                    var temp_obj = {};
                    temp_obj.ticket_no = obj.ticket_no;
                    temp_obj.customer_name = obj.customer_name;
                    temp_obj.subject = obj.subject;
                    temp_obj.due_date_time = moment(obj.due_date_time).format('hh:mm A, DD MMM YYYY') == "Invalid date" ? "Not Scheduled" : moment(obj.due_date_time).format('hh:mm A, DD MMM YYYY')
                    temp_obj.generate_time = moment(obj.generate_time).format('hh:mm A, DD MMM YYYY') == "Invalid date" ? "Not Available" : moment(obj.generate_time).format('hh:mm A, DD MMM YYYY')
                    temp_obj.priority = obj.priority;
                    temp_obj.status = obj.status;
                    temp_obj.ticket_id = obj.ticket_id;
                    var id = obj.ticket_id;
                    temp_obj.quick_peek =
                        <div>
                            <button className="btn btn-secondary btn-sm rounded p-3" type="button" onClick={(event) => { event.stopPropagation(); quickPeek(obj) }} title="quick peek">
                                {/* <i className="las la-file-import px-0" style={{ "color": "black", "font-size": "20px" }}></i> */}
                                <i className="las la-file-import px-0" style={{ "font-size": "20px" }}></i>
                            </button>
                        </div>

                    temp_obj.actions =
                        <div class="dropdown">
                            {
                                write ?
                                    <>
                                        <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={event => event.stopPropagation()}>
                                            <i className="fas fa-bars px-0"></i>
                                        </button>

                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            {/* <li><a onClick={(e) => { edit_details(obj) }} class="dropdown-item">Edit details</a></li> */}
                                            {/* <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Change status</a></li> */}
                                            <li><Link to={`/ticket-conversations/${id}`} class="dropdown-item" >Conversations</Link></li>
                                            <button to={`/ticket-conversations/${id}`} class="dropdown-item" onClick={event => { event.stopPropagation(); mark_spam(id) }} >Mark Spam</button>
                                        </ul>
                                    </>
                                    :
                                    null
                            }
                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        geteditdetails();

    }, [editmodal])

    async function viewconversations(ticket_id) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/conv-datatable`, {
            method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "tid": ticket_id, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        }
        );
        const body = await response.json();
        if (body.length) {
            setAllConv(body)
            setViewDetails(body.filter(conv => conv.direction == "Outgoing"));
            setViewInternalDetails(body.filter(conv => conv.direction == "Incoming"))
        }
    }

    const quickPeek = (ticket) => {
        // e.preventDefault();
        setQuickView(false)

        console.log(ticket.ticket_id)
        setPeekTicket(ticket)
        // setQuickView(!quickView)

        let p1 = viewconversations(ticket.ticket_id)

        Promise.all([p1])
            .then(() => {
                setQuickView(true)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const mark_spam = (id) => {

        var values = { "ticket_id": id, "page_name": page_name, "organization_id": organization_id, "service": "support" };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        swal({
            title: "Are you sure?",
            text: "Ticket will be marked as SPAM!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((val) => {
                console.log(val)
                if (val) {

                    axios.post(`${process.env.REACT_APP_SERVER_URL}/mark-spam`, values, headers).then((res) => {
                        if (res.data && res.data.operation == "success") {
                            swal("Great!", "Added to Spam successfully!", "success").then((value) => {
                                window.location.reload();
                            });
                        }
                        else {
                            swal("Oops!", "Something went wrong!", "error");
                        }
                    });
                }
            })
    }
    // console.log(allTickets)
    const setList = (value) => {
        let temp = allTickets
        if (value == "all") {
            setEditModal(temp)
            // console.log(temp)
        }
        else if (value == "own") {
            // let thisUserId = agentsGlobal.find(user => user.user_id == 'patraalok@email.com')
            let thisUserId = agentsGlobal.find(user => user.user_id == user_id)
            if (thisUserId !== undefined) {
                let ownTicket = temp.filter(ticket => ticket.owner_agent == thisUserId.user_unique_id || ticket.assign_agent == thisUserId.user_unique_id)
                setEditModal(ownTicket)
                // console.log(ownTicket)
            }
            else {
                setEditModal([])
                // console.log([])
            }
        }
        else if (value == "unassigned") {
            let unAssignedTicket = temp.filter(ticket => ticket.assign_agent == "")
            setEditModal(unAssignedTicket)
            // console.log(unAssignedTicket)
        }
        else if (value == "due") {
            //if due date is null? then generate time before 3days :else due date is before now
            let dateNow = new Date()
            dateNow.setDate(dateNow.getDate() - 3)
            // let overdueTicket = temp.filter(ticket => ((ticket.status == "Closed") && (new Date(ticket.generate_time) <= dateNow) || (ticket.due_date_time !== "0000-00-00 00:00:00" ? new Date(ticket.due_date_time) <= dateNow : false)))
            let overdueTicket = temp.filter(ticket => ((ticket.status !== "Closed") && (ticket.due_date_time == null ? (moment(ticket.generate_time).isSameOrBefore(moment(dateNow))) : (moment(ticket.due_date_time).isBefore(moment(new Date()))))))
            setEditModal(overdueTicket)
        }
        else if (value == "hold") {
            let unAssignedTicket = temp.filter(ticket => ticket.status == "on Hold")
            setEditModal(unAssignedTicket)
            // console.log(unAssignedTicket)
        }
        else if (value == "closed") {
            let unAssignedTicket = temp.filter(ticket => ticket.status == "Closed")
            setEditModal(unAssignedTicket)
            // console.log(unAssignedTicket)
        }
    }
    // console.log(data)

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
                {
                redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
                    <Redirect to={{ pathname: "/login" }} />
                </> : null)
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Tickets
                            {/* <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span> */}
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">

                            <div className='card-header'>
                                <div className="row mb-5">
                                    {state == 1 ?
                                        <>
                                            {/* <div className="col-md-6 fv-row"> */}
                                            <label className="fs-5 fw-bold my-4">Filter Tickets</label>
                                            <Select
                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                // className="form-control form-control-solid"
                                                options={[
                                                    { label: "All Tickets", value: "all" },
                                                    { label: "My Tickets", value: "own" },
                                                    { label: "Unassigned", value: "unassigned" },
                                                    { label: "Over Due", value: "due" },
                                                    { label: "on Hold Tickets", value: "hold" },
                                                    { label: "Closed Tickets", value: "closed" }
                                                ]}
                                                defaultValue={{ label: "All Tickets", value: "all" }}
                                                onChange={(e) => { setList(e.value) }}
                                            />
                                        </>
                                        :
                                        null}
                                </div>
                            </div>


                            <div className="card-body pt-6">
                                {
                                    data.length > 0 ?
                                        <>
                                            <MuiThemeProvider theme={createTheme({ palette: { type: userTheme } })}>

                                                <MUIDataTable
                                                    title={"User Details"}
                                                    data={data}
                                                    columns={columns}
                                                    options={options}
                                                />

                                            </MuiThemeProvider>


                                        </>
                                        :
                                        <>
                                            {
                                                state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    :
                                                    <>
                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5">Tickets</div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                        <div className="fs-6 text-gray-600"> </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                            }
                                        </>
                                }


                            </div>
                            {peekTicket && Object.keys(peekTicket).length !== 0 ?
                                <>
                                    <div className='position-absolute shadow' style={{ right: "0%", top: "0%" }}>
                                        <div className={` ${quickView ? '' : 'd-none'}`} >
                                            {/* <div className={`content d-flex flex-row-reverse `} > */}
                                            <div className="row gx-0">
                                                <div className='flex-fill col-3'>
                                                    <div className="card h-100">
                                                        <div className='card-header'>
                                                            <div className="d-flex align-items-center fs-4 fw-bold">
                                                                Ticket Details
                                                            </div>
                                                        </div>
                                                        <div className='card-body p-6' >

                                                            {/* <label className="fs-6 mt-1">Department</label>
                                                            <Select
                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                className="form-select-sm"
                                                                options={deptDropdown}
                                                                value={deptDropdown.find(dept => dept.value == ticketDetails.department)}
                                                                onChange={(e) => { setTicketDetails({ ...ticketDetails, department: e.value }) }}
                                                            /> */}
                                                            <label className="fs-6 mt-4">Assigned to</label>
                                                            <Select
                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                className="form-select-sm"
                                                                // options={agentDropdown}
                                                                // value={agentsGlobal.find(agent => agent.user_unique_id == peekTicket.assign_agent).user_id}
                                                                value={{ label: agentsGlobal.find(agent => agent.user_unique_id == peekTicket.assign_agent).user_id, value: agentsGlobal.find(agent => agent.user_unique_id == peekTicket.assign_agent).user_id }}
                                                            // onChange={(e) => { setTicketDetails({ ...ticketDetails, assign_agent: e.value }) }}
                                                            />
                                                            <label className="fs-6 mt-4">Due by</label>
                                                            <input type="datetime-local" name="start_date" className="form-control form-control-sm mt-2" style={{ marginLeft: "10px", width: "96%", borderRadius: "4px", height: "38px" }} id="start_date" placeholder="Due Date"
                                                                value={moment(peekTicket.due_date_time).format("YYYY-MM-DDTHH:mm")}
                                                            // onChange={(e) => {
                                                            //     setTicketDetails({ ...ticketDetails, due_date_time: e.target.value })
                                                            // }}
                                                            />
                                                            <div className='ms-3 my-1'>
                                                                {moment(peekTicket.due_date_time).isBefore(moment(new Date())) ?
                                                                    <>
                                                                        <i className="las la-hourglass-end text-danger fs-6"></i>
                                                                        <span className="text-danger fs-7"> Late by  {moment.duration(moment(new Date()).diff(moment(peekTicket.due_date_time))).format("D [day], H [hour and] m [min]")}</span>
                                                                    </>
                                                                    : null}

                                                            </div>
                                                            <label className="fs-6 mt-4">Status</label>
                                                            <Select
                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                className="form-select-sm"
                                                                // options={[
                                                                //     { label: "Open", value: "Open" },
                                                                //     { label: "on Hold", value: "on Hold" },
                                                                //     { label: "Closed", value: "Closed" }
                                                                // ]}
                                                                value={{ label: peekTicket.status, value: peekTicket.status }}
                                                            // defaultValue={{ label: "Open", value: "Open" }}
                                                            // onChange={(e) => { setTicketDetails({ ...ticketDetails, status: e.value }) }}

                                                            />
                                                            <h6 className=" mt-5">Additional Details</h6>
                                                            <label className="fs-6 mt-1">Priority</label>
                                                            <Select
                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                className="form-select-sm"
                                                                // options={priorityDropdown}
                                                                value={{ label: peekTicket.priority, value: peekTicket.priority }}
                                                            // // defaultValue={{ label: "Open", value: "Open" }}
                                                            // onChange={(e) => { setTicketDetails({ ...ticketDetails, priority: e.value }) }}
                                                            />
                                                            <label className="fs-6 mt-1">Classification</label>
                                                            <Select
                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                className="form-select-sm"
                                                                // options={classificationDropdown}
                                                                value={{ label: peekTicket.classification, value: peekTicket.classification }}
                                                            // onChange={(e) => { setTicketDetails({ ...ticketDetails, classification: e.value }) }}
                                                            />
                                                            <label className="fs-6 mt-1">Language</label>
                                                            <Select
                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                className="form-select-sm"
                                                                // options={language_list.language_list}
                                                                value={{ label: peekTicket.language, value: peekTicket.language }}
                                                            // onChange={(e) => { setTicketDetails({ ...ticketDetails, language: e.value }) }}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='flex-fill col-9'>
                                                    <div className="card h-100">
                                                        {/* Card header 1 Starts */}
                                                        <div className="card-header align-items-center py-5 gap-5">
                                                            <div className="d-flex align-items-center flex-wrap gap-2">
                                                                <a href="/ticket-list" className="btn btn-sm btn-icon btn-clear btn-active-light-primary me-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Back">
                                                                    <span className="svg-icon svg-icon-1 m-0">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                                                                            <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                                <div>
                                                                    <h2 className="fw-semibold me-3 my-1">{"#" + peekTicket.ticket_no + "  "}{peekTicket.subject}</h2>
                                                                    <span className='d-flex align-items-center'>
                                                                        <i class="las la-clock fs-4"></i><div className="text-gray-600 fs-8 fw-bold mx-1">{moment(peekTicket.generate_time).format("hh:mm A, D MMM yy")}</div>
                                                                    </span>
                                                                </div>
                                                                <span className='d-flex align-items-center ms-5' >
                                                                    {JSON.parse(peekTicket.tags).length > 0 ?
                                                                        <>
                                                                            {JSON.parse(peekTicket.tags).length >= 1 && <span className="badge badge-light-danger my-1 mx-1">{JSON.parse(peekTicket.tags)[0]}</span>}
                                                                            {JSON.parse(peekTicket.tags).length >= 2 && <span className="badge badge-light-primary my-1 mx-1">{JSON.parse(peekTicket.tags)[1]}</span>}
                                                                            {JSON.parse(peekTicket.tags).length > 2 && <span className="badge badge-light-warning my-1 mx-1">+{JSON.parse(peekTicket.tags).length - 2}</span>}
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                </span>
                                                                <span >
                                                                    <button type="button" className="btn-close" style={{ "margin": "5px 0px 0px 300px" }} onClick={(e) => { setQuickView(false) }} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* Card Header 1 Ends */}
                                                        <div className="card-body flex-row-reverse">
                                                            <div id="conversation" >
                                                                {ViewDetails.length ?
                                                                    <>
                                                                        {/* Conversation Fixed Head Starts */}
                                                                        <div class="d-flex flex-wrap gap-2 justify-content-between mb-8">
                                                                            <div class="d-flex align-items-center flex-wrap gap-2">
                                                                            </div>

                                                                            <div class="d-flex">

                                                                            </div>
                                                                        </div>
                                                                        {/* Conversation Fixed Head Ends */}
                                                                        {/* Conversation Scrollable Chats Starts */}
                                                                        <div className="overflow-auto pe-7" style={{ maxHeight: "400px" }}>
                                                                            {
                                                                                ViewDetails.map((obj) => {
                                                                                    return (
                                                                                        <>
                                                                                            {(obj.sender) !== user_id ?
                                                                                                <>
                                                                                                    <div data-kt-inbox-message="message_wrapper">
                                                                                                        <div class="d-flex flex-wrap gap-2 flex-stack cursor-pointer" data-kt-inbox-message="header">
                                                                                                            <div class="d-flex align-items-center">
                                                                                                                <div class="symbol symbol-50 me-4">
                                                                                                                    <span class="symbol-label" style={{ backgroundImage: "url(/assets/media/avatars/blank.png)" }}></span>
                                                                                                                </div>

                                                                                                                <div class="pe-5">
                                                                                                                    <div class="d-flex align-items-center flex-wrap gap-1">
                                                                                                                        <a href="#" class="fw-bold text-dark text-hover-primary">{obj.sender}</a>
                                                                                                                        <span class="svg-icon svg-icon-7 svg-icon-success mx-3">
                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                                                                <circle fill="currentColor" cx="12" cy="12" r="8" />
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                        <span class="text-muted fw-bold">1 day ago</span>
                                                                                                                    </div>

                                                                                                                    <div data-kt-inbox-message="details">
                                                                                                                        <span class="text-muted fw-semibold">to me</span>
                                                                                                                        <a href="#" class="me-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">
                                                                                                                            <span class="svg-icon svg-icon-5 m-0">
                                                                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                    <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                                                                                                                                </svg>
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px p-4" data-kt-menu="true">
                                                                                                                            <table class="table mb-0">
                                                                                                                                <tbody>
                                                                                                                                    <tr>
                                                                                                                                        <td class="w-75px text-muted">From</td>
                                                                                                                                        <td>Emma Bold</td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td class="text-muted">Date</td>
                                                                                                                                        <td>22 Sep 2022, 9:23 pm</td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td class="text-muted">Subject</td>
                                                                                                                                        <td>Trip Reminder. Thank you for flying with us!</td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td class="text-muted">Reply-to</td>
                                                                                                                                        <td>emma@intenso.com</td>
                                                                                                                                    </tr>
                                                                                                                                </tbody>
                                                                                                                            </table>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div class="text-muted fw-semibold mw-450px d-none" data-kt-inbox-message="preview">With resrpect, i must disagree with Mr.Zinsser. We all know the most part of important part....</div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="d-flex align-items-center flex-wrap gap-2">
                                                                                                                <span class="fw-semibold text-muted text-end me-3">{moment(obj.generate_time).utcOffset("+05:30").format('llll')}</span>
                                                                                                                <div class="d-flex">
                                                                                                                    <a href="#" class="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Star">
                                                                                                                        <span class="svg-icon svg-icon-2 m-0">
                                                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                <path d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z" fill="currentColor" />
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                    <a href="#" class="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark as important">
                                                                                                                        <span class="svg-icon svg-icon-2 m-0">
                                                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                <path d="M16.0077 19.2901L12.9293 17.5311C12.3487 17.1993 11.6407 17.1796 11.0426 17.4787L6.89443 19.5528C5.56462 20.2177 4 19.2507 4 17.7639V5C4 3.89543 4.89543 3 6 3H17C18.1046 3 19 3.89543 19 5V17.5536C19 19.0893 17.341 20.052 16.0077 19.2901Z" fill="currentColor" />
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                    <a href="#" class="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Reply">
                                                                                                                        <span class="svg-icon svg-icon-2 m-0">
                                                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                                                                                                                                <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                                                                                                                                <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                    <a href="#" class="btn btn-sm btn-icon btn-clear btn-active-light-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Settings">
                                                                                                                        <span class="svg-icon svg-icon-2 m-0">
                                                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                <rect x="10" y="10" width="4" height="4" rx="2" fill="currentColor" />
                                                                                                                                <rect x="10" y="3" width="4" height="4" rx="2" fill="currentColor" />
                                                                                                                                <rect x="10" y="17" width="4" height="4" rx="2" fill="currentColor" />
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="collapse fade show" data-kt-inbox-message="message">
                                                                                                            <div class="py-5">
                                                                                                                <iframe srcDoc={obj.content} width="70%" height="100px" style={{ 'filter': 'invert(1)' }}></iframe>
                                                                                                                {JSON.parse(obj.attachment).length ?
                                                                                                                    <>
                                                                                                                        <div className='mt-1'>
                                                                                                                            <i class="las la-link mx-2" style={{ "font-size": "20px" }}></i>
                                                                                                                            {
                                                                                                                                JSON.parse(obj.attachment).map((obj1, index) => {
                                                                                                                                    return (
                                                                                                                                        <a href={`${process.env.REACT_APP_STORAGE_SUPPORT}ticket_attachments/${obj1}`} >
                                                                                                                                            <span className="badge badge-secondary rounded m-1">{obj1.slice(obj1.indexOf("_", obj1.indexOf("_") + 1) + 1)}</span>
                                                                                                                                        </a>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    : null}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="separator my-6"></div>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <div data-kt-inbox-message="message_wrapper">
                                                                                                        <div class="d-flex flex-wrap gap-2 flex-stack cursor-pointer" data-kt-inbox-message="header">

                                                                                                            <div class="d-flex align-items-center">
                                                                                                                <div class="d-flex">
                                                                                                                    <a href="#" class="btn btn-sm btn-icon btn-clear btn-active-light-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Settings">
                                                                                                                        <span class="svg-icon svg-icon-2 m-0">
                                                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                <rect x="10" y="10" width="4" height="4" rx="2" fill="currentColor" />
                                                                                                                                <rect x="10" y="3" width="4" height="4" rx="2" fill="currentColor" />
                                                                                                                                <rect x="10" y="17" width="4" height="4" rx="2" fill="currentColor" />
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                    </a>

                                                                                                                    <a href="#" class="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Reply">
                                                                                                                        <span class="svg-icon svg-icon-2 m-0">
                                                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                                                                                                                                <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                                                                                                                                <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                    <a href="#" class="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark as important">
                                                                                                                        <span class="svg-icon svg-icon-2 m-0">
                                                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                <path d="M16.0077 19.2901L12.9293 17.5311C12.3487 17.1993 11.6407 17.1796 11.0426 17.4787L6.89443 19.5528C5.56462 20.2177 4 19.2507 4 17.7639V5C4 3.89543 4.89543 3 6 3H17C18.1046 3 19 3.89543 19 5V17.5536C19 19.0893 17.341 20.052 16.0077 19.2901Z" fill="currentColor" />
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                    <a href="#" class="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Star">
                                                                                                                        <span class="svg-icon svg-icon-2 m-0">
                                                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                <path d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z" fill="currentColor" />
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                                <span class="fw-semibold text-muted text-end me-3">{moment(obj.generate_time).utcOffset("+05:30").format('llll')}</span>

                                                                                                            </div>
                                                                                                            <div class="d-flex align-items-center flex-wrap gap-2">
                                                                                                                <div class="pe-5">
                                                                                                                    <div class="d-flex align-items-center flex-wrap gap-1">
                                                                                                                        <span class="text-muted fw-bold">1 day ago</span>
                                                                                                                        <span class="svg-icon svg-icon-7 svg-icon-success mx-3">
                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                                                                <circle fill="currentColor" cx="12" cy="12" r="8" />
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                        <a href="#" class="fw-bold text-dark text-hover-primary">{user.name}</a>

                                                                                                                    </div>
                                                                                                                    <div data-kt-inbox-message="details">
                                                                                                                        {/* <span class="text-muted fw-semibold">to me</span> */}
                                                                                                                        <a href="#" class="me-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">
                                                                                                                            <span class="svg-icon svg-icon-5 m-0">
                                                                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                    <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                                                                                                                                </svg>
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px p-4" data-kt-menu="true">
                                                                                                                            <table class="table mb-0">
                                                                                                                                <tbody>
                                                                                                                                    <tr>
                                                                                                                                        <td class="w-75px text-muted">From</td>
                                                                                                                                        <td>Emma Bold</td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td class="text-muted">Date</td>
                                                                                                                                        <td>22 Sep 2022, 9:23 pm</td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td class="text-muted">Subject</td>
                                                                                                                                        <td>Trip Reminder. Thank you for flying with us!</td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td class="text-muted">Reply-to</td>
                                                                                                                                        <td>emma@intenso.com</td>
                                                                                                                                    </tr>
                                                                                                                                </tbody>
                                                                                                                            </table>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div class="text-muted fw-semibold mw-450px d-none" data-kt-inbox-message="preview">With resrpect, i must disagree with Mr.Zinsser. We all know the most part of important part....</div>
                                                                                                                </div>
                                                                                                                <div class="symbol symbol-50 me-4">
                                                                                                                    <span class="symbol-label" style={{ backgroundImage: `url(${user.img})` }}></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="collapse fade show" data-kt-inbox-message="message">
                                                                                                            <div class="py-5">
                                                                                                                <iframe srcDoc={obj.content} width="70%" height="100px"></iframe>
                                                                                                                {JSON.parse(obj.attachment).length ?
                                                                                                                    <>
                                                                                                                        <div className='mt-1'>
                                                                                                                            <i class="las la-link mx-2" style={{ "font-size": "20px" }}></i>
                                                                                                                            {
                                                                                                                                JSON.parse(obj.attachment).map((obj1, index) => {
                                                                                                                                    return (
                                                                                                                                        <a href={`${process.env.REACT_APP_STORAGE_SUPPORT}ticket_attachments/${obj1}`} >
                                                                                                                                            {/* <span className="badge badge-secondary rounded m-1">{obj1.split("_")[obj1.split("_").length - 1]}</span> */}
                                                                                                                                            <span className="badge badge-secondary rounded m-1">{obj1.slice(obj1.indexOf("_", obj1.indexOf("_") + 1) + 1)}</span>
                                                                                                                                        </a>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    : null}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="separator my-6"></div>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        {/* Conversation Scrollable Chats Ends */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className={`mt-20`} >
                                                                            <div className="d-flex justify-content-center" >
                                                                                <div className="align-items-center" >
                                                                                    <img src="\assets\media\icons\conversation.png" height="150px"></img>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex justify-content-center" >
                                                                                <div className="align-items-center" >
                                                                                    <span className=" fs-1"> No Conversation Started</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className='d-flex justify-content-center'>
                                                                                <span className='text-black-50'>Start conversation with Customer</span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null}

                        </div>
                    </div>
                </div>
            </div>
            

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />

        </>);

};

export default ListTicket;
