import React, { lazy, Suspense, useState, useEffect , useRef} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom';
import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

function AddTeam(props) {
    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);
    const [user, setUser] = useState({});
    // const [PurchaseDetails, setPurchaseDetails] = useState([]);
    const [state, setstate] = useState(2);

    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    const [teamName, setTeamName] = useState("");
    const [departmentList, setDepartmentList] = useState([]);
    const [teamDepartment, setTeamDepartment] = useState("");
    const [agentsGlobal, setAgentsGlobal] = useState([]);
    const [teamAgents, setTeamAgents] = useState([]);
    const [teamDesc, setTeamDesc] = useState("");
    const [organization_id, setOrganization_id] = useState("");
    const [organization_list, setorganization_list] = useState([]);
    const [buttonStat, setButtonStat] = useState(1)
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    var page_name = "add-team";


    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };
    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }
    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
      }, [])
   
    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);

                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                window.location.href = "/login"
            });
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)

        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (temp == 1) break;

                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)

                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);

                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);

                    });
            }
            else {
                setRedirect_to_login(1);

            }
        }
        organization_id != "" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer) => {
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);
    useEffect(() => {
        async function getuserdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.length > 0) {
                let agent_roles = body.filter(obj => obj.user_role.includes("Agent"))
                setAgentsGlobal(agent_roles)
            }
            else {
                setAgentsGlobal([])
            }

        }
        if (read || write) {
            user.email && visibility && getuserdetails();
        }
        async function getdepartmentdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/department-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "support" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.length > 0) {
                setDepartmentList(body.map(obj => { return { label: obj.department_title, value: obj.department_id } }))
            }
            else {
                setDepartmentList([])
            }

        }
        if (read || write) {
            user.email && visibility && getdepartmentdetails();
        }

    }, [user, visibility, read, write])

    const submitTeam = (e) => {
        e.preventDefault();
        var object = {};
        //object.organization_id = organization_id;
        object.team_name = teamName.trim()
        object.team_description = teamDesc.trim()
        object.department = teamDepartment

        object.page_name = page_name;
        object.organization_id = organization_id
        object.service = "support"

        if (object.department == "") {
            swal("Warning!", "Select a department", "warning");
            return false;
        }
        // var nexp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (object.team_name == "") {
            swal("Warning!", "Team Name cannot be a empty", "warning");
            return false;
        }
        else if (!object.team_name.match(nexp)) {
            swal("Warning!", "Team Name cannot contain certain symbols ", "warning");
            return false;
        }
        if (teamAgents.length < 1) {
            swal("Warning!", "No Agents Selected", "warning");
            return false;
        }

        object.team_agents = teamAgents

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        // console.log(object)
        setButtonStat(0)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-team`, object, headers).then((res) => {
            setButtonStat(1)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Department added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else if (res.data.operation === "same_team_name") {
                swal("Oops!", "Use a different team name!!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        }).catch(function (error) {
            console.log(error)
        });
    }
    // console.log(agentsGlobal)
    // console.log(teamDepartment)
    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
              {
                redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
                    <Redirect to={{ pathname: "/login" }} />
                </> : null)
            }
            {user.email ?
                <>

                    <div className="d-flex flex-column flex-column-fluid">
                        <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Create Team
                                    {/* <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span> */}
                                    <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                </h1>
                            </div>
                        </div>

                        <div id="kt_app_content" className="app-content flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container container-xxl">
                                <div className="card">
                                    <form className="form">
                                        <div className="card-body border-top p-9">
                                            <div className=" py-10 px-lg-17">
                                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                                    <div className="row mb-5">
                                                        <div className="col-md-6 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Select Department</label>
                                                            <Select    
                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                className="form-control form-control-solid"
                                                                options={departmentList}
                                                                onChange={(e) => { setTeamDepartment(e.value) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Team Name</label>
                                                            <input id="team_name" type="text" className="form-control form-control-solid " placeholder="" name="team-name" value={teamName} onChange={(e) => { setTeamName(e.target.value) }} readOnly={write ? false : true} />
                                                        </div>

                                                    </div>

                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Agents</label>
                                                            <Select
                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                isMulti
                                                                className="form-control form-control-solid"
                                                                options={agentsGlobal.map((e) => { return { label: e.user_id, value: e.user_unique_id } })}
                                                                onChange={(val) => { setTeamAgents(val.map(a => a.value)) }}
                                                            />

                                                        </div>
                                                    </div>


                                                    <div className="row mb-5">

                                                        <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">Description</label>
                                                            <textarea id="team_description" className="form-control form-control-solid" rows="3" name="discription" placeholder="" autocomplete="off" value={teamDesc} onChange={(e) => { setTeamDesc(e.target.value) }} readOnly={write ? false : true}></textarea>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                            <div className="modal-footer flex-center">
                                                <button type="submit"
                                                    className="btn btn-primary rounded"
                                                    onClick={(e) => { submitTeam(e) }}
                                                    disabled={write ? false : true}
                                                    data-kt-indicator={buttonStat ? "off" : "on"}
                                                    disabled={write && buttonStat ? false : true}
                                                >
                                                    <span className="indicator-label">Submit</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="d-flex flex-column flex-column-fluid">
                        <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Create Team
                                    <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                    <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                </h1>
                            </div>
                        </div>

                        <div id="kt_app_content" className="app-content flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container container-xxl">
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <br />

                                    {state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <>
                                            <div className="flex-lg-row-fluid me-lg-15">
                                                <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                                    <div className="card-header"></div>
                                                    <div className="card-body pt-0">
                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">Please Login</h4>
                                                                    <div className="fs-6 text-gray-600">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }
           

            
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />


        </>
    );
}
export default AddTeam;